/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    helmetTitle: "Wendor | Login",
    setUp: "Setup Your Machine",
    machineId: "Machine ID",
    password: "Password",
    login: "Login",
    pickUpCode: "Pick Up Code",
    submit: "Submit",
  },
  app: {
    helmetTitle: "Wendor | App",
    title: "Wendor",
    needHelp: "Need Help?",
    poweredBy: "Powered By",
  },
  products: {
    helmetTitle: "Wendor | Products",
    trayWithIndex: "Tray {{index}}",
    addProductToCart: "ADD",
    clearCart: "Clear Cart",
    itemsInCart: "{{items}} item(s) in cart",
    checkout: "Pay Now",
    infoTextWait: "Please wait while we process your order",
  },
  payment: {
    helmetTitle: "Wendor | Payments",
    payment: "Payment",
    duration: "{{duration}} seconds",
    cancelPayment: "Cancel Payment",
    orderId: "Order ID: {{orderId}}",
    paymentSuccess: "Payment Successful!",
    orderFailedToStart: "Order failed",
    orderFailedToStartMessage: "We are processing your refund",
    cancel: {
      title: "Cancel Payment",
      subtitle: "Are you sure you want to cancel the order?",
      continue: "Continue Payment",
      cancel: "Cancel Payment",
    },
    paytm: {
      scanQrCode: "Scan QR Code",
      scanAndPay: "Scan and complete your payment",
    },
    cash: {
      enterPhone: "Enter phone number",
      pleaseWait: "Please wait",
      dontInsert: "Don't insert the cash right now",
      checkingCashMachine: "Checking the Cash Machine",
      proceed: "Proceed",
      insertCash: "Please Insert Cash",
      insertInMachine: "Insert the mentioned amount in the machine",
      toBePaid: "Balance to be paid",
      inserted: "Total inserted",
      bill: "Total bill",
      cancelAndSendBalance: "Cancel and send balance to Swift",
      sendToSwiftPrompt:
        "Your amount will be sent to our SWIFT wallet. You can use SWIFT wallet for future transactions.",
    },
    swift: {
      enterPhone: "Enter phone number",
      enterOtp: "Enter the OTP",
      sendOtp: "Send OTP",
      verify: "Verify",
      changeNumber: "Change number",
      accountExists: "Account with this number have insufficient points",
      enterValidNumber: "Please enter a valid number",
    },
  },
  cart: {
    helmetTitle: "Wendor | Cart",
    cart: "Cart",
    productCoil: "Product Coil",
    price: "Price",
    total: "Total",
  },
  vend: {
    helmetTitle: "Wendor | Vending",
    cart: "Cart",
    productCoil: "Product Coil",
    price: "Price",
    total: "Total",
    amazing: "Excellent",
    justok: "OK",
    horrible: "Not satisfied",
    rateExperience: "Please rate your experience with this vending machine",
    collectProduct: "Please collect your product from the vending machine",
    supportText:
      "If you need any support scan the qr or call for contacting support team",
    supportPhoneNumber: "+91 8527898948",
  },
  feedback: {
    continue: "Continue Shopping",
    were_sorry: "We are sorry!",
    sorry_prompt: "Please scan the QR code to leave us a feedback",
    thank_you: "Thank you!",
    thank_prompt:
      "Thank you for using our vending machine! We would like to know your experience. Please scan the QR code to fill a short feedback!",
  },
};
