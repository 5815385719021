import { Dialog, DialogProps, Stack, Typography } from "@mui/material";
import { useState } from "react";
import PressableButton from "src/components/pressables/PressableButton";
import PressableDialog from "src/components/pressables/PressableDialog";
import { getCurrentLanguage } from "src/i18n";
import { useSelector } from "src/redux/store";
import { THEME } from "src/theme/globalTheme";
import IColorType from "src/theme/new-ui/color.schema";
import {
  COLOR_PRIMARY,
  COLOR_TEXT_ON_WHITE,
  DialogColor,
} from "src/theme/new-ui/component.color";

export interface Props extends DialogProps {
  marginScale?: number;
  dialogRadius?: number;
  dialogShadow?: number;
  titleSize?: number;
  subtitleSize?: number;
  actionSize?: number;
  actionHeight?: number;
  actionShadow?: number;
  actionRadius?: number;
  colorType?: IColorType;
  onContinue: VoidFunction;
  onCancel: VoidFunction;
}

export default function DialogCancelPayment({
  marginScale = 1,
  dialogRadius = 24,
  dialogShadow = 10,
  titleSize = 24,
  subtitleSize = 16,
  actionSize = 12,
  actionHeight = 32,
  actionShadow = 4,
  actionRadius = 16,
  colorType = DialogColor,
  open = false,
  children,
  onContinue,
  onCancel,
  ...other
}: Props) {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const [showDialog, setShowDialog] = useState(open);

  return (
    <PressableDialog
      open={showDialog}
      shadowHeight={dialogShadow}
      radius={dialogRadius}
    >
      <Stack
        width={"100%"}
        direction={"column"}
        alignItems={"center"}
        sx={{
          padding: 5,
        }}
      >
        <Typography
          sx={{ marginTop: 3 * marginScale }}
          fontSize={`${titleSize}px`}
          fontWeight={700}
          variant="h4"
          color={THEME.primaryColor}
        >
          {displayTexts?.payment.cancel.title}
        </Typography>
        <Typography
          sx={{ marginTop: 2 * marginScale }}
          fontSize={`${subtitleSize}px`}
          fontWeight={600}
          variant="subtitle1"
          color={COLOR_TEXT_ON_WHITE}
          align="center"
        >
          {displayTexts?.payment.cancel.subtitle}
        </Typography>

        <Stack
          width={"70%"}
          direction={"column"}
          sx={{ marginTop: 5 * marginScale }}
          spacing={2 * marginScale}
        >
          <PressableButton
            isFilled={true}
            buttonHeight={actionHeight}
            fontSize={actionSize}
            borderRadius={actionRadius}
            shadowHeight={actionShadow}
            text={displayTexts?.payment.cancel.cancel}
            onClick={onCancel}
          />
          <PressableButton
            buttonHeight={actionHeight}
            fontSize={actionSize}
            borderRadius={actionRadius}
            shadowHeight={actionShadow}
            text={displayTexts?.payment.cancel.continue}
            onClick={onContinue}
          />
        </Stack>
      </Stack>
    </PressableDialog>
  );
}
