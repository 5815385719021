import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import { useSelector } from "src/redux/store";
import { OrderService } from "src/services";
import { fCurrency } from "src/utils/formatNumber";
import { getCurrentLanguage } from "src/i18n";
import {
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
  COLOR_WHITE,
  CardPrimarySelectedColor,
  CardPrimaryUnSelectedColor,
} from "src/theme/new-ui/component.color";
import PressableButton from "src/components/pressables/PressableButton";
import PressableDialPad from "src/components/pressables/dialpad/PressableDialPad";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import Countdown from "src/components/pressables/Countdown";
import PressableCard from "src/components/pressables/PressableCard";
import generateAlpha from "src/theme/new-ui/color.utils";

interface Props {
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
  inputs: {
    phoneNo: string;
    otp?: string;
  };
  openErrorDialog: VoidFunction;
  setInputs: Dispatch<
    SetStateAction<{
      phoneNo: string;
      otp?: string;
    }>
  >;
}

const STEPS = {
  INIT: "INIT",
  LISTEN_INIT_RESPONSE: "LISTEN_INIT_RESPONSE",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
};

function CashPaymentMethodVariant2({
  cancelOrder,
  proceedToNextStep,
  inputs,
  setInputs,
  openErrorDialog,
}: Props) {
  const mqttObserverInstance = MQTTObserver.getInstance();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );
  const { currencyDetails } = useMachineConfigurationContext();
  const { checkout } = useSelector((state) => state.product);

  const { payment, orderId, total } = useSelector(
    (state) => state.product.checkout
  );

  const [currentStep, setCurrentStep] = useState(STEPS.INIT);

  const data = useRef({
    request_id: orderId,
    payment_method: PAYMENT_METHODS.CASH,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCash, setTotalCash] = useState(total);

  const [dialed, setDialed] = useState("");
  const [code, setCode] = useState("+91");

  useEffect(() => {
    if (payment.phoneNo) {
      setInputs({
        phoneNo: payment.phoneNo,
      });
    }
  }, []);

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2({
        ...data.current,
        phone: phoneNumber,
      });

      setIsLoading(false);
      // if (response.error) cancelOrder();
      if (!response.error) {
        setCurrentStep(STEPS.LISTEN_INIT_RESPONSE);
        startCashModule();
      }
    } catch (error) {
      console.error(error);
      // TODO: Handle Error
      openErrorDialog();
    }
  };

  const startCashModule = async () => {
    try {
      const response = await OrderService.initResponseCashModule({
        status: "READY",
        requestId: orderId,
        comment: "success",
        code: 100,
      });
      // if (response.error) cancelOrder();
    } catch (error) {
      console.error(error);
      // TODO: Handle Error
      openErrorDialog();
    }
  };

  const clearAllSubscriptions = () => {
    mqttObserverInstance.unsubscribeMultiple(subscriptionIdList.current);
  };

  const subscriptionIdList = useRef<Array<string>>([]);

  useEffect(() => {
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.CASH_INIT_RESPONSE,
        onCashInitResponse
      )
    );
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.CASH_RECEIVED,
        onCashRecived
      )
    );
    return clearAllSubscriptions;
  }, []);

  const onCashInitResponse = (data: any) => {
    if (data.status) setCurrentStep(STEPS.PAYMENT_DETAILS);
  };

  const onCashRecived = async (data: any) => {
    try {
      const response = await OrderService.cashTxnUpdateV2({
        status: "success",
        comment: "no comments",
        note_uuid: data.uuid,
        amount_added: data.billCollected,
        requestId: orderId,
        type: data.paymentType,
      });
      if (response.error) cancelOrder();
      if (response.is_txn_completed) {
        proceedToNextStep();
      } else {
        setTotalCash(response.amount_left);
      }
    } catch (error) {
      console.error(error);
      openErrorDialog();
    }
  };

  useEffect(() => {
    switch (currentStep) {
      case STEPS.INIT:
        {
          setTitle(displayTexts ? displayTexts?.payment.cash.enterPhone : "");
          setSubtitle("");
        }
        break;
      case STEPS.LISTEN_INIT_RESPONSE:
        {
          setTitle(displayTexts ? displayTexts?.payment.cash.pleaseWait : "");
          setSubtitle(
            displayTexts ? displayTexts?.payment.cash.dontInsert : ""
          );
        }
        break;
      case STEPS.PAYMENT_DETAILS:
        {
          setTitle(displayTexts ? displayTexts?.payment.cash.insertCash : "");
          setSubtitle(
            displayTexts ? displayTexts?.payment.cash.insertInMachine : ""
          );
        }
        break;
    }
  }, [currentStep]);

  const getCashComponent = () => {
    switch (currentStep) {
      case STEPS.INIT:
        return (
          <Stack direction={"column"} alignItems={"center"} spacing={0}>
            <Typography
              fontSize={64}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {`${
                currencyDetails && currencyDetails.isSuffix
                  ? ""
                  : currencyDetails?.symbol + " "
              }${fCurrency(checkout.total)}${
                currencyDetails && currencyDetails.isSuffix
                  ? " " + currencyDetails?.symbol
                  : ""
              }`}
            </Typography>
            <PressableDialPad
              actionButtonHeight={64}
              actionButtonRadius={16}
              actionButtonShadow={8}
              actionTextSize={24}
              digitDimen={100}
              digitFontSize={32}
              digitShadowHeight={16}
              digitMarginTop={4}
              digitSpacing={8}
              inputMarginBottom={4}
              inputSpacing={2}
              inputBorderWidth={2}
              inputPadHor={32}
              inputPadVert={20}
              inputFieldHeight={92}
              inputFieldRadius={16}
              inputFieldShadow={12}
              inputTextSize={32}
              dialed={dialed}
              setDialed={setDialed}
              code={code}
              setCode={setCode}
              isPhoneField
              actionText={`${displayTexts?.payment.cash.proceed}`}
              onDialed={(data0, data1) => {
                setPhoneNumber(data0);
              }}
              onAction={async (data) => {
                setIsLoading(true);
                callCheckoutApi();
              }}
            />
          </Stack>
        );
      case STEPS.LISTEN_INIT_RESPONSE:
        return (
          <Stack
            direction={"column"}
            alignItems={"center"}
            spacing={0}
            marginTop={6}
          >
            <Typography
              fontSize={24}
              fontWeight={400}
              color={COLOR_TEXT_ON_WHITE}
            >
              {displayTexts?.payment.cash.checkingCashMachine}
            </Typography>
            <img
              src="/assets/configAssets/payment_cash_machine.gif"
              width={800}
            />
            {/* <CircularProgress color="error" /> */}
          </Stack>
        );
      case STEPS.PAYMENT_DETAILS:
        return (
          <Stack
            direction={"column"}
            spacing={4}
            paddingLeft={8}
            paddingRight={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Countdown onTimeout={cancelOrder} fontSize={24} />

            <Stack direction={"column"} spacing={6}>
              <PressableCard
                cardWidth={"100%"}
                cardHeight={220}
                borderRadius={32}
                colorType={CardPrimarySelectedColor}
                hasStroke
                strokeWidth={2}
              >
                <Stack
                  direction={"column"}
                  paddingTop={"10px"}
                  paddingLeft={"20px"}
                  paddingRight={"20px"}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={"28px"}
                    color={COLOR_GRAY}
                  >
                    {displayTexts?.payment.cash.toBePaid}
                  </Typography>
                  <Grid container alignItems="baseline">
                    {!currencyDetails?.isSuffix && (
                      <Grid item xs>
                        <Typography
                          fontWeight={600}
                          fontSize={"28px"}
                          color={COLOR_GRAY}
                          align="center"
                        >
                          {currencyDetails?.symbol}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={11}>
                      <Typography
                        fontWeight={800}
                        fontSize={"128px"}
                        color={COLOR_TEXT_ON_WHITE}
                        align="center"
                      >
                        {totalCash}
                      </Typography>
                    </Grid>
                    {currencyDetails?.isSuffix && (
                      <Grid item xs alignItems={"center"}>
                        <Typography
                          fontWeight={600}
                          fontSize={"28px"}
                          color={COLOR_GRAY}
                          align="center"
                        >
                          {currencyDetails?.symbol}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </PressableCard>

              <Stack width={"100%"} direction={"row"} spacing={4}>
                <PressableCard
                  cardWidth={"360px"}
                  cardHeight={180}
                  borderRadius={32}
                  colorType={CardPrimaryUnSelectedColor}
                >
                  <Stack
                    direction={"column"}
                    paddingTop={"10px"}
                    paddingLeft={"20px"}
                    paddingRight={"20px"}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"28px"}
                      color={COLOR_GRAY}
                    >
                      {displayTexts?.payment.cash.inserted}
                    </Typography>
                    <Grid container alignItems="baseline">
                      {!currencyDetails?.isSuffix && (
                        <Grid item xs>
                          <Typography
                            fontWeight={600}
                            fontSize={"24px"}
                            color={COLOR_GRAY}
                            align="center"
                          >
                            {currencyDetails?.symbol}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={11}>
                        <Typography
                          fontWeight={800}
                          fontSize={"96px"}
                          color={COLOR_TEXT_ON_WHITE}
                          align="center"
                        >
                          {checkout.total - totalCash}
                        </Typography>
                      </Grid>
                      {currencyDetails?.isSuffix && (
                        <Grid item xs alignItems={"center"}>
                          <Typography
                            fontWeight={600}
                            fontSize={"24px"}
                            color={COLOR_GRAY}
                            align="center"
                          >
                            {currencyDetails?.symbol}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Stack>
                </PressableCard>

                <PressableCard
                  cardWidth={"360px"}
                  cardHeight={180}
                  borderRadius={32}
                  colorType={CardPrimaryUnSelectedColor}
                >
                  <Stack
                    direction={"column"}
                    paddingTop={"10px"}
                    paddingLeft={"20px"}
                    paddingRight={"20px"}
                  >
                    <Typography
                      fontWeight={700}
                      fontSize={"28px"}
                      color={COLOR_GRAY}
                    >
                      {displayTexts?.payment.cash.bill}
                    </Typography>
                    <Grid container alignItems="baseline">
                      {!currencyDetails?.isSuffix && (
                        <Grid item xs>
                          <Typography
                            fontWeight={600}
                            fontSize={"24px"}
                            color={COLOR_GRAY}
                            align="center"
                          >
                            {currencyDetails?.symbol}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={11}>
                        <Typography
                          fontWeight={800}
                          fontSize={"96px"}
                          color={COLOR_TEXT_ON_WHITE}
                          align="center"
                        >
                          {checkout.total}
                        </Typography>
                      </Grid>
                      {currencyDetails?.isSuffix && (
                        <Grid item xs alignItems={"center"}>
                          <Typography
                            fontWeight={600}
                            fontSize={"24px"}
                            color={COLOR_GRAY}
                            align="center"
                          >
                            {currencyDetails?.symbol}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Stack>
                </PressableCard>
              </Stack>

              <PressableButton
                fullWidth
                buttonHeight={60}
                borderRadius={30}
                shadowHeight={8}
                fontSize={24}
                colorType={{
                  main: COLOR_WHITE,
                  shadow: `${generateAlpha(COLOR_GRAY, 0.3)}`,
                  text: COLOR_GRAY,
                }}
                text={displayTexts?.payment.cash.cancelAndSendBalance}
                onClick={() => {}}
              />

              <Typography fontWeight={400} fontSize={"24px"} color={COLOR_GRAY}>
                {displayTexts?.payment.cash.sendToSwiftPrompt}
              </Typography>
            </Stack>
          </Stack>
        );
      default:
        return <>Some error occured</>;
    }
  };

  return (
    <>
      {isLoading && <CircularProgress color="error" />}
      {!isLoading && (
        <Stack
          display={"flex"}
          flex={1}
          direction={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            sx={{
              position: "absolute",
              top: 200,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
            marginBottom={6}
          >
            <Typography
              fontSize={40}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {title}
            </Typography>
            <Typography
              fontSize={24}
              fontWeight={400}
              color={COLOR_TEXT_ON_WHITE}
            >
              {subtitle}
            </Typography>

            {getCashComponent()}
          </Stack>

          <Stack
            sx={{
              position: "absolute",
              bottom: 0,
            }}
            direction={"column"}
            alignItems={"center"}
            spacing={0}
          >
            <PressableButton
              text={displayTexts?.payment.cancelPayment}
              buttonHeight={60}
              borderRadius={30}
              fontSize={24}
              buttonWidth={300} // todo: remove this line while removing en. suffix from language file
              onClick={cancelOrder}
            />
            <Typography
              fontSize={24}
              fontWeight={600}
              color={COLOR_GRAY}
              marginTop={"12px"}
            >
              {displayTexts?.payment.orderId.replace(
                "{{orderId}}",
                checkout.orderId
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}

export default CashPaymentMethodVariant2;
