import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import { Box, Container, Stack, Typography } from "@mui/material";
// routes
// redux
import { useDispatch, useSelector } from "../../../redux/store";
// @types
// components

import { useSettingsContext } from "../../../components/settings";
// sections
import { OrderService } from "src/services";
import { PATH_AFTER_LOGIN } from "src/config";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import { getCurrentLanguage } from "src/i18n";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import { COLOR_GRAY } from "src/theme/new-ui/component.color";
//import FeedbackVendingMaching from "src/assets/feedback/feedback_screen.gif";
import PressableFeedback from "src/components/pressables/PressableFeedback";
import DialogFeedbackQR from "src/sections/dialogs/FeedbackQRDialog";
import { resetVendingState } from "src/redux/slices/vending";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { TIMEOUT_DEFAULT_VALUES } from "src/constants/index";
import { SocketContext } from "src/context/socketContext/SocketContext";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";
import { PATH_DASHBOARD } from "src/routes/paths";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
let feedbackTimeoutID: ReturnType<typeof setTimeout>;

export default function RefundPageVariant1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { refundData, successfullyVendedItemsCount } = location.state;

  const socketObserverInstance = SocketObserver.getInstance();
  const socket = useContext(SocketContext);
  const subscriptionIdList = useRef<Array<string>>([]);

  const { machineConfiguration, currencyDetails } =
    useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { checkout } = useSelector((state) => state.product);
  console.log({ checkout });
  //const { orderId } = checkout;

  const { themeStretch } = useSettingsContext();

  const { vendingStatus, orderId } = useSelector((state) => state.vending);
  const [feedbackType, setFeedbackType] = useState(-1);
  const [showFeedbackQr, setShowFeedbackQr] = useState(false);
  const [startTimeOut, setStartTimeOut] = useState(false);

  const handelFeedbackButtonsClick = async (value: number, bill_id: string) => {
    setFeedbackType(value);
    const response = await OrderService.orderFeedback({
      bill_id,
      value,
    });
    if (!response.error) postFeedback();
  };

  const clearSubscriptions = () => {
    console.log("hello RefundPageVariant1");
    clearTimeout(feedbackTimeoutID);
  };

  const postFeedback = () => {
    setShowFeedbackQr(true);
  };

  const postFeedbackImpl = () => {
    setShowFeedbackQr(false);
    clearSubscriptions();
    navigate(PATH_AFTER_LOGIN);
  };

  const handlePayoutResponse = async (data: any) => {
    const res = await OrderService.currencyModuleResponse({
      machineId: data.machineId,
      requestId: data.requestId,
      message: "API called",
      totalCoinPayout: data.totalCoinPayout,
      totalCashPayout: data.totalCashPayout,
    });

    return res;
  };

  const handleEventAfterRefund = async (data: any) => {
    if (data.topic === "PAYOUT_RESPONSE") {
      const res = await handlePayoutResponse(data);

      if (res.error) {
        console.log("error in payout response");
      } else {
        console.log("Refund success");
      }
      if (successfullyVendedItemsCount === 0) {
        await OrderService.orderFeedback({
          bill_id: orderId,
          value: 0,
        });
        navigate(PATH_DASHBOARD.eCommerce.shop);
      } else {
        navigate(PATH_DASHBOARD.eCommerce.feedback);
      }
    } else {
      setStartTimeOut(true);
    }
  };

  useEffect(() => {
    dispatch(resetVendingState());
    socket?.sendMessage(
      SocketEvents.BROADCAST,
      JSON.stringify(refundData.cash_refund_topic)
    );

    subscriptionIdList.current.push(
      socketObserverInstance.subscribe(
        SocketEvents.BROADCAST,
        handleEventAfterRefund
      )
    );
    return () => {
      subscriptionIdList.current.forEach((id) =>
        socketObserverInstance.unsubscribe(id)
      );
      clearSubscriptions();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      navigate(PATH_AFTER_LOGIN);
    }, 15000);
  }, [startTimeOut === true]);

  return (
    <>
      <Helmet>
        <title>{displayTexts?.vend.helmetTitle}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
        }}
      >
        <PageHeaderCrumb
          elevation={10}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />

        <Stack
          sx={{
            display: "flex",
            marginTop: "40px",
            alignItems: "center",
          }}
          spacing={0}
        >
          <Typography
            marginTop={5}
            fontSize={16}
            fontWeight={400}
            lineHeight={"24px"}
            align="center"
            color={"#454F5B"}
            textOverflow="false"
            sx={{
              height: "36px",
              left: "0.5px",
              top: "438px",
              "border-radius": "nullpx",
            }}
          >
            Please collect the change {currencyDetails?.symbol}{" "}
            {refundData.data.amount}
          </Typography>
        </Stack>
      </Container>
    </>
  );
}
