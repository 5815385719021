// @mui
import { Typography, Stack, DialogProps } from "@mui/material";
// components
import Iconify from "../../../../components/iconify";
import { DialogAnimate } from "../../../../components/animate";
// assets
import { OrderCompleteIllustration } from "../../../../assets/illustrations";
import { getCurrentLanguage } from "src/i18n";

import paymentImage from "src/assets/paymentStatus/5569552_2903544.gif";
import Image from "src/components/image/Image";
import { PaymentScreenLoader } from "src/components/paymentScreenLoader";
import { useContext, useEffect, useState } from "react";
import { OrderService, VendingService, MQTTService } from "src/services";
import { dispatch, useSelector } from "src/redux/store";
import { initVending, setVendingItems } from "src/redux/slices/vending";
import { useNavigate } from "react-router";
import { PATH_AFTER_LOGIN } from "src/config";
import { PATH_DASHBOARD } from "src/routes/paths";
import { resetCart } from "src/redux/slices/product";
import { SocketContext } from "src/context/socketContext/SocketContext";
import React from "react";
import SocketObserver from "src/observers/socketObserver";
import { SocketEvents } from "src/observers/socketObserver";
import OverlayModalError from "src/components/error/OverlayModalError";
import { MachineConfigurationContext } from "src/machineConfig/machineConfig.context";
import { ChannelsOrEvents } from "src/observers/mqttObserver";

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onReset?: VoidFunction;
  onDownloadPDF?: VoidFunction;
  isMachineReady?: Boolean;
  requestId: string;
}

let waitingIntervalId: ReturnType<typeof setTimeout>;
let retry: boolean = true;

export default function LockerCheckoutOrderComplete({
  open,
  requestId,
  onReset,
  onDownloadPDF,
}: // isMachineReady,
  Props) {
  const socketObserverInstance = SocketObserver.getInstance();
  const socket = React.useContext(SocketContext);
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );
  const machineConfigContext = useContext(MachineConfigurationContext);

  const [isError, setIsError] = useState<boolean>(false);

  const { orderId } = useSelector((state) => state.product.checkout);
  const navigate = useNavigate();
  const [isVendNowSuccessful, setIsVendNowSuccessful] =
    useState<Boolean>(false);

  const clearAllSubscriptions = () => {
    clearTimeout(waitingIntervalId);
  };

  const startTimer = () => {
    waitingIntervalId = setTimeout(async () => {
      try {
        console.log("LockerCheckoutOrderComplete.startTimer: ");
        setIsError(true);
        clearAllSubscriptions();
        const response = await OrderService.cacelOrder({ request_id: orderId });
        if (response.error) return; // TODO: Call order refund api here!
        setTimeout(() => {
          dispatch(resetCart());
          navigate(PATH_AFTER_LOGIN);
        }, 5000); // TODO: CANCEL THE ORDER TO THE VMC AS WELL PUBLISH TO SOCKET TO STOP VENDING OR HAVE SINGLE ITEM VENDING
      } catch (error) {
        console.error(error);
      }
    }, machineConfigContext?.machineConfig?.paymentPage?.orderInitTimeout || 30000);
  };

  const vendNow = async () => {
    try {
      const response = await VendingService.lockerVendNow(requestId.toString());
      console.log("VendingService.vendNow :: ", { response });
      if (response.error) {
        console.log(response.error);
        return { error: true, message: response.error, items: [], orderId: "" };
      }
      // startTimer();
      dispatch(
        initVending({
          items: response.data.items,
          orderId: response.data.order_id,
        })
      );
      return {
        items: response.data.tokenizedItems,
        orderId: response.data.order_id,
        error: false,
        message: "",
        decodedItems: response.data.items,
      };
    } catch (error) {
      console.log(error);
      return { items: [], orderId: "", error: true, message: error.message };
    }
  };

  const handleOrderInitResponse = (data: any) => {
    console.log("handleOrderInitResponse :: ", data);
    clearAllSubscriptions();
    setIsVendNowSuccessful(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const response: {
          items: any;
          message: string;
          error: boolean;
          orderId: string;
          decodedItems?: any;
        } = await vendNow();
        if (!response.error) {
          // const itemsForLocker = [];

          // for (let index = 0; index < response.decodedItems.length; index++) {
          //   const element = response.decodedItems[index];

          //   if (element.show_pos.includes("L")) {
          //     itemsForLocker.push(element);
          //   }
          // }

          // if (itemsForLocker.length > 0) {
          //   MQTTService.MQTTService.getInstance().sendMessage(
          //     `VEND/${machineConfigContext?.machineData.machine_id}/LOCKER`,
          //     itemsForLocker[0]
          //   );

          //   setTimeout(() => {
          //     clearAllSubscriptions();
          //     setIsVendNowSuccessful(true);
          //   }, 1000);
          // } else {
          socket?.sendMessage("ORDER_INIT", JSON.stringify(response));
          //   }
          // console.log("itemsForLocker", itemsForLocker[0]);

          startTimer();
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      clearAllSubscriptions();
    };
  }, []);

  useEffect(() => {
    const socketConnectSubscriptionId = socketObserverInstance.subscribe(
      SocketEvents.ORDER_INIT_RESPONSE,
      handleOrderInitResponse
    );

    return () => {
      socketObserverInstance.unsubscribe(socketConnectSubscriptionId);
    };
  }, []);

  useEffect(() => {
    if (isVendNowSuccessful) {
      clearAllSubscriptions();
      navigate(PATH_DASHBOARD.eCommerce.vend);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVendNowSuccessful]);

  return (
    <>
      {isError && (
        <OverlayModalError
          error={displayTexts?.payment.orderFailedToStart || "Order Failed"}
          errorMessage={
            displayTexts?.payment.orderFailedToStartMessage ||
            "We are processing your refund"
          }
        />
      )}
      {!isError && (
        <DialogAnimate
          open={open}
          PaperProps={{
            sx: {
              maxWidth: { md: "calc(100% - 48px)" },
              maxHeight: { md: "calc(100% - 100px)" },
              paddingTop: 5,
              paddingBottom: 5,
            },
          }}
        >
          <Stack
            spacing={2}
            sx={{
              m: "auto",
              maxWidth: 580,
              textAlign: "center",
              px: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h4">Authentication Successful!</Typography>
            <Image
              key={paymentImage}
              alt="logo card"
              src={paymentImage}
              sx={{
                height: 260,
              }}
            />
            <Typography variant="subtitle2">Initiating Process</Typography>
            <PaymentScreenLoader />
          </Stack>
        </DialogAnimate>
      )}
    </>
  );
}
