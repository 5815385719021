import { useRef, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import FormProvider, { RHFTextField } from "../../components/hook-form";
import KeyboardWrapper from "src/components/keyBpardWrapper/KeyboardWrapper";
import { KeyboardInput } from "react-simple-keyboard";
import Lang from "src/i18n/en";
import { OrderService } from "src/services";

// ----------------------------------------------------------------------

type FormValuesProps = {
  machineId?: string;
  pickUpCode: string;
  afterSubmit?: string;
};

type Props = {
  setAuthResponse: any;
};
export default function AuthPickUpCodeForm({ setAuthResponse }: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    pickUpCode: Yup.string().required("Pick up code is required"),
  });

  const defaultValues = {
    pickUpCode: "1234",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    clearErrors,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await OrderService.verifyPickUpCode({
        pickup_code: inputs.pickUpCode,
      });

      if (res.error) {
        throw new Error(res.message);
      }

      setAuthResponse(res);
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: error.message || "For any technical issues, please contact local IT team on second floor",
      });

      setTimeout(() => {
        clearErrors();
      }, 3000);
    }
  };

  const [inputs, setInputs] = useState<{ pickUpCode: string }>({
    pickUpCode: "",
  });
  // const [layoutName, setLayoutName] = useState("default");
  const [inputName, setInputName] = useState("default");
  const keyboard = useRef({
    setInput: (inputVal: string) => {},
    setCaretPosition: (inputVal: number) => {},
  });

  const onChangeAll = (inputs: KeyboardInput) => {
    /* console.log("Inputs changed", inputs); */
    setInputs({ pickUpCode: inputs.pickUpCode });
  };

  const onChangeInput = (event: any) => {
    const inputVal = event.target.value;

    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };
  const getInputValue = (inputName: "pickUpCode") => {
    return inputs[inputName] || "";
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="pickUpCode"
          label={Lang.login.pickUpCode}
          type={showPassword ? "text" : "pickUpCode"}
          value={getInputValue("pickUpCode")}
          onChange={onChangeInput}
          onFocus={() => {
            setInputName("pickUpCode");
          }}
          focused={inputName === "pickUpCode"}
          InputProps={{
            readOnly: true,
          }}
          autoFocus={true}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          {Lang.login.submit}
        </LoadingButton>
      </Stack>

      <KeyboardWrapper
        inputName={inputName}
        keyboardRef={keyboard}
        onChangeAll={onChangeAll}
      />
    </FormProvider>
  );
}
