import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import EcommerceShopPageVariant2 from "src/pages/dashboard/shop/EcommerceShopPageVariant2";
import EcommerceShopPageVariant1 from "src/pages/dashboard/shop/EcommerceShopPageVariant1";
import { useIdleTimer } from "react-idle-timer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetCart } from "src/redux/slices/product";
import { MACHINE_TYPE_SETTINGS } from "src/constants";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import PickupCodeVariant from "src/pages/dashboard/shop/PickupCodeVariant";

function ShopVariant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { machineData, machineConfiguration, refreshMachine } = useMachineConfigurationContext();
  const isInventoryUpdated: boolean = useSelector(
    (state: any) => state.product.isInventoryUpdated
  );

  const theme = useTheme();

  console.log(theme.palette.primary.main, "theme.palette.primary.main");

  useEffect(() => {
    if (isInventoryUpdated) {
      refreshMachine();
    }

    return () => {};
  }, []);

  const onCartTimeout = () => {
    dispatch(resetCart());
  };

  const cartTimer = useIdleTimer({
    onIdle: onCartTimeout,
    timeout:
      machineConfiguration.kiosk_cart_timeout * 1000 ||
      MACHINE_TYPE_SETTINGS.productDisplayPage.kioskCartTimeout,
    throttle: 500,
  });

  const onScreenSaverTimeout = () => {
    if (
      machineConfiguration.show_screensaver !== undefined &&
      machineConfiguration.show_screensaver === true
    ) {
      navigate(PATH_DASHBOARD.eCommerce.screensaver);
    }
  };

  const screensaverTimer = useIdleTimer({
    onIdle: onScreenSaverTimeout,
    timeout:
      machineConfiguration.kiosk_screensaver_timeout * 1000 ||
      MACHINE_TYPE_SETTINGS.productDisplayPage.kioskScreensaverTimeout,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      cartTimer.getRemainingTime();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    let interval: any = null;
    if (
      machineConfiguration.show_screensaver !== undefined &&
      machineConfiguration.show_screensaver === true
    ) {
      interval = setInterval(() => {
        screensaverTimer.getRemainingTime();
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    machineConfiguration.show_screensaver !== undefined &&
      machineConfiguration.show_screensaver === true,
  ]);

  //switch (VARIANT) {
  switch (machineData.machine_type) {
    case "big":
      return <EcommerceShopPageVariant1 />;
    case "pickupcode":
      return <PickupCodeVariant />;
    default:
      return <EcommerceShopPageVariant2 />;
  }
}

export default ShopVariant;
