interface Props {
  setRetryClicked: any;
}

const DisabledCashModule = ({ setRetryClicked }: Props) => {
  return (
    <div>
      {/* <button onClick={() => {console.log("Clicked");setRetryClicked(true)}}>Try Again</button> */}
    </div>
  );
};

export default DisabledCashModule