import { Helmet } from "react-helmet-async";
// @mui
import { Container, Typography, Box, Stack, Grid } from "@mui/material";
import { useSelector } from "../../../redux/store";
import { IVendingItem } from "src/@types/vending";
import { getCurrentLanguage } from "src/i18n";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import { COLOR_GRAY } from "src/theme/new-ui/component.color";
import VendingCard from "src/components/vending-components/VendingCard";

const vendedItems: Array<string> = [];

export default function EcommerceVendingPageVariant3() {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { checkout } = useSelector((state) => state.product);
  const { vendingItems, currentItemIndex, rfidData } = useSelector(
    (state) => state.vending
  );

  return (
    <>
      <Helmet>
        <title>{displayTexts?.vend.helmetTitle}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
        }}
      >
        <PageHeaderCrumb
          elevation={10}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />

        <Stack alignItems={"center"}>
          <Typography
            mt={"96px"}
            textAlign={"center"}
            variant="h6"
            fontFamily={"Public Sans"}
            fontSize="18px"
            fontWeight="700"
          >
            Please wait while we vend your product!
          </Typography>
          {rfidData !== null &&
            rfidData !== undefined &&
            checkout.payment.value === "rfid" && (
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"space-around"}
                marginTop={"50px"}
              >
                <Typography>RFID ID: {rfidData?.rfid_id}</Typography>
                <Typography>
                  RFID Balanace: {rfidData?.updated_balance}
                </Typography>
              </Stack>
            )}
          <Grid container mt={"32px"} justifyContent={"center"} width={"360px"}>
            {vendingItems.map((item, indexI) => {
              console.log("items: ", item, indexI, currentItemIndex);
              if (
                item.vend_status === "started" &&
                currentItemIndex !== indexI
              ) {
                // pending
                return <VendingCard position={item.show_pos} color="#DFE3E8" />;
              } else if (currentItemIndex === indexI) {
                // currently vending
                return (
                  <VendingCard
                    position={item.show_pos}
                    color="#454F5B"
                    status="Vending"
                  />
                );
              } else if (item.vend_status) {
                // success
                if (vendedItems.includes(item.bill_item_id)) {
                  return (
                    <VendingCard
                      position={item.show_pos}
                      color="#36B37E"
                      status="Success"
                    />
                  );
                } else {
                  vendedItems.push(item.bill_item_id);
                  return (
                    <VendingCard
                      position={item.show_pos}
                      color="#36B37E"
                      status="Success"
                    />
                  );
                }
              } else {
                // failed
                if (vendedItems.includes(item.bill_item_id)) {
                  return (
                    <VendingCard
                      position={item.show_pos}
                      color="#D80000"
                      status="Failed"
                    />
                  );
                } else {
                  vendedItems.push(item.bill_item_id);
                  return (
                    <VendingCard
                      position={item.show_pos}
                      color="#D80000"
                      status="Failed"
                    />
                  );
                }
              }
            })}
          </Grid>
        </Stack>

        <Box
          sx={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            position={"fixed"}
            sx={{ bottom: "40px" }}
            fontSize={14}
            fontWeight={600}
            color={COLOR_GRAY}
          >
            {displayTexts?.payment.orderId.replace(
              "{{orderId}}",
              checkout.orderId
            )}
          </Typography>
          <PageFooterCrumb elevation={10} />
        </Box>
      </Container>
    </>
  );
}
