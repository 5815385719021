import { CircularProgress, Grid, Typography } from '@mui/material';
import { THEME } from 'src/theme/globalTheme';

interface Props {
  infoText?: string;
}

export default function OverlayLoading({ infoText }: Props) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
      direction="column"
    >
      {infoText && (
        <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
          {infoText}
        </Typography>
      )}
      <Grid item>
        <CircularProgress sx={{ color: THEME.primaryColor }} />
      </Grid>
    </Grid>
  );
}
