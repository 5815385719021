import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState, useMemo, useRef } from "react";
import { Container, Box } from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import {
  initOrderCycle,
  createPaymentMethod,
  resetCart,
} from "../../../redux/slices/product";
import {
  setProducts,
  updateInventoryStatus,
} from "../../../redux/slices/product";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { OrderService } from "src/services";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import ShopProductListVariant1 from "src/sections/@dashboard/e-commerce/shop/ShopProductListVariant1";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import PageFooterAction from "../../../sections/@dashboard/crumbs/variant1/PageFooterActions";
import { getCurrentLanguage } from "src/i18n";
import OverlayLoading from "src/components/overlay-loading/OverlayLoading";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import { useNotificationContext } from "src/context/notification/useNotificationContext";

export default function EcommerceShopPageVariant1() {
  const mqttObserverInstance = MQTTObserver.getInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [infoText, setInfoText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const {
    products: _products,
    machineConfiguration,
    refreshMachine,
  } = useMachineConfigurationContext();
  const { products: reduxProducts } = useSelector((state) => state.product);
  const { cart } = useSelector((state) => state.product.checkout);
  const notification = useNotificationContext();

  useEffect(() => {
    dispatch(setProducts(_products));
  }, [dispatch, _products]);

  const products = useMemo(() => reduxProducts, [reduxProducts]);

  const handelConfirmCheckout = async () => {
    try {
      setIsLoading(true);
      setInfoText(displayTexts?.products?.infoTextWait || "Please wait");

      if (!isLoading) {
        // Use setTimeout to allow the state update to happen before the API call
        setTimeout(async () => {
          console.log(isLoading);
          console.log(machineConfiguration.kiosk_default_payment_method);

          if (
            machineConfiguration &&
            machineConfiguration.kiosk_default_payment_method
          ) {
            const paymentMethod =
              machineConfiguration.kiosk_default_payment_method as keyof typeof PAYMENT_METHODS;
            dispatch(
              createPaymentMethod({
                payment: { value: PAYMENT_METHODS[paymentMethod] },
              })
            );
          } else {
            dispatch(
              createPaymentMethod({
                payment: { value: PAYMENT_METHODS["PAYTM"] },
              })
            );
          }

          const data = cart.map((product) => ({
            pos: product.pos,
            quantity: product.quantity,
          }));
          try {
            const response = await OrderService.generateRequestId(data);
            console.log(response);

            if (response?.error) {
              handleCheckoutFailed();
              return;
            }

            dispatch(initOrderCycle({ request_id: response.request_id }));
            setIsLoading(false);

            navigate(PATH_DASHBOARD.eCommerce.checkout, {
              replace: true,
            });
          } catch (error) {
            handleCheckoutFailed();
          }
          
        }, 0);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleCheckoutFailed = () => {
    notification.notify("Checkout failed: Please contact support");
    dispatch(resetCart());
    setIsLoading(false);
    setInfoText("");
  };

  const onKioskRefreshRequest = () => {
    if (window.location.pathname === PATH_DASHBOARD.eCommerce.shop) {
      refreshMachine();
    } else {
      dispatch(updateInventoryStatus(true));
    }
  };

  const subscriptionIdList = useRef<Array<string>>([]);

  useEffect(() => {
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.KIOSK_REFRESH,
        onKioskRefreshRequest
      )
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{displayTexts?.products.helmetTitle}</title>
      </Helmet>
      <>
        <Container maxWidth={false} disableGutters>
          {isLoading && <OverlayLoading infoText={infoText} />}
          <PageHeaderCrumb
            elevation={10}
            handleHelpPageClick={() => {
              console.log("handleHelpPageClick");
            }}
          />
          <Box sx={{ marginTop: "56px" }}>
            <ShopProductListVariant1
              products={products}
              loading={!products.length}
            />
          </Box>
          <Box sx={{ marginBottom: "30px" }}>
            <PageFooterAction
              positiveAction={handelConfirmCheckout}
              negativeAction={() => {}}
            />
          </Box>
        </Container>
      </>
    </>
  );
}
