import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import { OrderService } from "src/services";
import { SocketContext } from "src/context/socketContext/SocketContext";
import { verifyRFIDdata } from "src/services/order.service";
import { Stack, Typography } from "@mui/material";
import PressableButton from "src/components/pressables/PressableButton";
import { getCurrentLanguage } from "src/i18n";
import {
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
} from "src/theme/new-ui/component.color";
import PressableDialPad from "src/components/pressables/dialpad/PressableDialPad";
import Countdown from "src/components/pressables/Countdown";
import { fCurrency } from "src/utils/formatNumber";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { updateRfidData } from "src/redux/slices/vending";

interface Props {
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
}

function RFIDPaymentMethodVariant1({ cancelOrder, proceedToNextStep }: Props) {
  const [buttonClick, setButtonClick] = useState(false);
  useEffect(() => {
    callCheckoutApi();
  }, [buttonClick]);

  useEffect(() => {
    subscriptionIdList.current.push(
      socketObserverInstance.subscribe(
        SocketEvents.BROADCAST,
        handleEnablingRFID
      )
    );
    return () => {
      socket?.sendMessage(
        "BROADCAST",
        JSON.stringify({ topic: "RFID_DISABLED" })
      );
      subscriptionIdList.current.forEach((id) =>
        socketObserverInstance.unsubscribe(id)
      );
    };
  }, [buttonClick]);

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2(data.current);
      if (!response.error) {
        socket?.sendMessage(
          "BROADCAST",
          JSON.stringify({ topic: "RFID_ENABLED", ...response })
        );
        // setIsTryAgain(false);
      } else {
        // setIsTryAgain(true);
        return;
      }
    } catch (error) {
      // TODO: Handle Error
      console.error(error);
      //   openErrorDialog();
    }
  };

  const { checkout } = useSelector((state: any) => state.product);
  const socketObserverInstance = SocketObserver.getInstance();
  const socket = React.useContext(SocketContext);
  const subscriptionIdList = useRef<Array<string>>([]);
  const [rfidStatus, setRfidStatus] = useState("INITIATING");
  const [showDialPad, setShowDialPad] = useState(false);
  const [dialed, setDialed] = useState("");
  const [code, setCode] = useState("+91");
  const [pin, setPin] = useState("");
  const [title, setTitle] = useState("Please Wait");
  const [subtitle, setSubtitle] = useState("We are checking card acceptor");
  const [imageForRFID, setImageForRFID] = useState(
    "/assets/configAssets/rfid-load.gif"
  );

  const [startTimeOut, setStartTimeOut] = useState(false);
  const { currencyDetails, machineConfiguration } =
    useMachineConfigurationContext();
  const dispatch = useDispatch();

  const displayTexts = getCurrentLanguage(
    useSelector((state: any) => state.seetings.language).language
  );

  const setRFIDBalanceData = (res: any) => {
    if (machineConfiguration.kiosk_rfid_show_user_details) {
      dispatch(
        updateRfidData({
          updated_balance: res.user_details.updated_balance,
          rfid_id: res.user_details.rfid_id,
        })
      );
    }
  };

  const handleEnablingRFID = async (data: any) => {
    if (data.topic === "RFID_ENABLED" && data.status === true) {
      setStartTimeOut(false);
      setRfidStatus("ENABLED");
      setTitle("Machine detected!");
      setSubtitle("Please tap your RFID card");
      setImageForRFID("/assets/configAssets/rfid-tap.gif");
    } else if (data.topic === "RFID_DISABLED" && data.status === false) {
      setImageForRFID("");
      setRfidStatus("DISABLED");
      setTitle("Error");
      setSubtitle("RFID reader not detected");
    } else if (data.topic === "RFID_SEND_TAG" && data.status === true) {
      setImageForRFID("");
      const res = await verifyRFIDdata({
        requestId: data.requestId,
        rfidData: data.rfidData,
      });

      if (res.error || res.insufficient_balance) {
        setRfidStatus("DISABLED");
        setTitle("Error");
        setSubtitle(res.message);
        setImageForRFID("");
      } else {
        if (res.type === "pin") {
          setShowDialPad(true);
          setTitle("Enter your RFID pin");
          setSubtitle("");
        }

        if (!res.error && res.type === undefined) {
          setRFIDBalanceData(res);
          proceedToNextStep();
        }
      }

      return;
    }
  };

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.RFID,
  });

  const authorizeRFID = async () => {
    const res = await OrderService.authRfidPayment({
      request_id: data.current.request_id,
      pin: pin,
    });

    if (!res.error) {
      setRFIDBalanceData(res);
      proceedToNextStep();
    }
  };

  const handleRetry = () => {
    setRfidStatus("INITIATING");
    setTitle("Please Wait");
    setSubtitle("We are checking card acceptor");
    setImageForRFID("/assets/configAssets/rfid-load.gif");
    setButtonClick(!buttonClick);
  };

  // if (startTimeOut) {
  //   setTimeout(() => {
  //     setRfidStatus("DISABLED");
  //     setTitle("Error");
  //     setSubtitle("RFID reader not detected");
  //     setImageForRFID("");
  //   }, 5000);
  // }

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 100,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
        marginBottom={3}
        width={"240px"}
      >
        <Typography fontSize={20} fontWeight={700} color={COLOR_TEXT_ON_WHITE}>
          {title}
        </Typography>
        <Typography
          fontSize={12}
          textAlign={"center"}
          fontWeight={400}
          color={COLOR_TEXT_ON_WHITE}
        >
          {subtitle}
        </Typography>
        <img src={imageForRFID} />
        {showDialPad && (
          <>
            <Countdown onTimeout={cancelOrder} />
            <Typography
              fontSize={28}
              fontWeight={700}
              color={COLOR_TEXT_ON_WHITE}
            >
              {`${
                currencyDetails && currencyDetails.isSuffix
                  ? ""
                  : currencyDetails?.symbol + " "
              }${fCurrency(checkout.total)}${
                currencyDetails && currencyDetails.isSuffix
                  ? " " + currencyDetails?.symbol
                  : ""
              }`}
            </Typography>
            <PressableDialPad
              dialed={dialed}
              setDialed={setDialed}
              code={code}
              setCode={setCode}
              isPhoneField={false}
              actionText={`${displayTexts?.payment.cash.proceed}`}
              onDialed={(data0, data1) => {
                setPin(data0);
              }}
              onAction={async (data) => {
                authorizeRFID();
              }}
            />
          </>
        )}
        {rfidStatus === "DISABLED" && (
          <Stack sx={{ mt: 2 }}>
            <PressableButton
              text={"Retry"}
              buttonWidth={150}
              onClick={handleRetry}
            />
          </Stack>
        )}
      </Stack>

      <Stack
        sx={{
          position: "absolute",
          bottom: 20,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
      >
        <Stack direction={"row"} spacing={1}>
          <PressableButton
            text={displayTexts?.payment.cancelPayment}
            buttonWidth={120} // todo: remove this line while removing en. suffix from language file
            onClick={cancelOrder}
          />
        </Stack>
        <Typography
          fontSize={12}
          fontWeight={600}
          color={COLOR_GRAY}
          marginTop={"12px"}
        >
          {displayTexts?.payment.orderId.replace(
            "{{orderId}}",
            data.current.request_id
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default RFIDPaymentMethodVariant1;
