// ----------------------------------------------------------------------

export type IVendingItem = {
  position: string;
  show_pos: string;
  product_id: string;
  product_name: string;
  product_description: string | null;
  image_mini: string;
  image: string;
  price: number;
  bill_item_id: string;
  vend_status: string;
};

// ----------------------------------------------------------------------

export type IVendingState = {
  activeStep: number;
  vendingItems: IVendingItem[];
  orderId: string;
  currentItem: IVendingItem;
  currentItemIndex: number;
  vendingStatus: VendingStatus;
  rfidData: { updated_balance: number; rfid_id: string } | undefined | null;
};

export enum VendingStatus {
  STARTED = "STARTED",  
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

/* export type IProductState = {
  isLoading: boolean;
  error: Error | string | null;
  products: IProduct[];
  product: IProduct | null;
  checkout: IProductCheckoutState;
}; */

// -----------------
export type ItemResponse = {
  mid: string;
  oid: string;
  oiid: string;
  oir: string;
  items: string;
  s: boolean;
}
// --------------------