import { Grid, Stack, Typography } from "@mui/material";
import Countdown from "../pressables/Countdown";
import {
  CardPrimarySelectedColor,
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
  CardPrimaryUnSelectedColor,
} from "src/theme/new-ui/component.color";
import PressableCard from "../pressables/PressableCard";
import { useSelector } from "react-redux";
import { getCurrentLanguage } from "src/i18n";
import { CurrencyDetailsType } from "src/machineConfig/types";

interface Props {
  totalRemainingCash: number;
  totalCashInserted: number;
  totalCash: number;
  currencyDetails: CurrencyDetailsType | null | undefined;
  cancelOrder: VoidFunction;
}

const EnabledCashModule = ({
  totalRemainingCash,
  totalCashInserted,
  totalCash,
  currencyDetails,
  cancelOrder,
}: Props) => {
  const displayTexts = getCurrentLanguage(
    useSelector((state: any) => state.seetings.language).language
  );

  return (
    <Stack
      direction={"column"}
      spacing={2}
      paddingLeft={4}
      paddingRight={4}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Countdown onTimeout={cancelOrder} />

      <Stack direction={"column"} spacing={3}>
        <PressableCard
          cardWidth={"100%"}
          cardHeight={100}
          colorType={CardPrimarySelectedColor}
          hasStroke
        >
          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            spacing={-1}
            paddingLeft={"10px"}
            paddingTop={"5px"}
            paddingRight={"10px"}
          >
            <Typography fontWeight={700} fontSize={"12px"} color={COLOR_GRAY}>
              {displayTexts?.payment.cash.toBePaid}
            </Typography>
            <Grid container alignItems="baseline" flexWrap={"nowrap"}>
              {!currencyDetails?.isSuffix && (
                <Grid item xs>
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={COLOR_GRAY}
                    align="center"
                  >
                    {currencyDetails?.symbol}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={11}>
                <Typography
                  fontWeight={800}
                  fontSize={"64px"}
                  color={COLOR_TEXT_ON_WHITE}
                  align="right"
                >
                  {totalRemainingCash}
                </Typography>
              </Grid>
              {currencyDetails?.isSuffix && (
                <Grid item xs>
                  <Typography
                    fontWeight={600}
                    fontSize={"14px"}
                    color={COLOR_GRAY}
                    align="center"
                  >
                    {currencyDetails?.symbol}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Stack>
        </PressableCard>

        <Stack
          width={"100%"}
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
        >
          <PressableCard
            cardWidth={"120px"}
            cardHeight={84}
            colorType={CardPrimaryUnSelectedColor}
          >
            <Stack direction={"column"} padding={"5px"}>
              <Typography fontWeight={700} fontSize={"12px"} color={COLOR_GRAY}>
                {displayTexts?.payment.cash.inserted}
              </Typography>
              <Grid container alignItems="baseline" flexWrap={"nowrap"}>
                {!currencyDetails?.isSuffix && (
                  <Grid item xs>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={COLOR_GRAY}
                      align="center"
                    >
                      {currencyDetails?.symbol}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={11}>
                  <Typography
                    fontWeight={800}
                    fontSize={"48px"}
                    color={COLOR_TEXT_ON_WHITE}
                    align="right"
                  >
                    {totalCashInserted}
                  </Typography>
                </Grid>
                {currencyDetails?.isSuffix && (
                  <Grid item xs>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={COLOR_GRAY}
                      align="center"
                    >
                      {currencyDetails?.symbol}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </PressableCard>

          <PressableCard
            cardWidth={"120px"}
            cardHeight={84}
            colorType={CardPrimaryUnSelectedColor}
          >
            <Stack direction={"column"} padding={"5px"}>
              <Typography fontWeight={700} fontSize={"12px"} color={COLOR_GRAY}>
                {displayTexts?.payment.cash.bill}
              </Typography>
              <Grid container alignItems="baseline" flexWrap={"nowrap"}>
                {!currencyDetails?.isSuffix && (
                  <Grid item xs>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={COLOR_GRAY}
                      align="center"
                    >
                      {currencyDetails?.symbol}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={11}>
                  <Typography
                    fontWeight={800}
                    fontSize={"48px"}
                    color={COLOR_TEXT_ON_WHITE}
                    align="right"
                  >
                    {totalCash}
                  </Typography>
                </Grid>
                {currencyDetails?.isSuffix && (
                  <Grid item xs>
                    <Typography
                      fontWeight={600}
                      fontSize={"14px"}
                      color={COLOR_GRAY}
                      align="center"
                    >
                      {currencyDetails?.symbol}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </PressableCard>
        </Stack>

        {/* <PressableButton
                fullWidth
                colorType={{
                  main: COLOR_WHITE,
                  shadow: `${generateAlpha(COLOR_GRAY, 0.3)}`,
                  text: COLOR_GRAY,
                }}
                text={displayTexts?.payment.cash.cancelAndSendBalance}
                onClick={() => {}}
              />

              <Typography fontWeight={400} fontSize={"12px"} color={COLOR_GRAY}>
                {displayTexts?.payment.cash.sendToSwiftPrompt}
              </Typography> */}
      </Stack>
    </Stack>
  );
};

export default EnabledCashModule;
