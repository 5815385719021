// @mui
import { Stack, Button, IconButton, Typography } from "@mui/material";
// routes
// utils
import { fCurrency } from "../../../../utils/formatNumber";
// redux
import { useDispatch, useSelector } from "../../../../redux/store";
import {
  addToCart,
  decreaseQuantity,
  increaseQuantity,
} from "../../../../redux/slices/product";
// @types
import { IProduct } from "../../../../@types/product";
// components
import Iconify from "../../../../components/iconify";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { useMemo, useRef, useState } from "react";
import PressableCard from "src/components/pressables/PressableCard";
import { getCurrentLanguage } from "src/i18n";
import {
  CardPrimaryColor,
  CardPrimaryUnSelectedColor,
} from "src/theme/new-ui/component.color";
import { useNotificationContext } from "src/context/notification/useNotificationContext";
import { THEME } from "src/theme/globalTheme";

// ----------------------------------------------------------------------

type Props = {
  product: IProduct;
};

export default function ShopProductCardVariant1({ product }: Props) {
  const {
    id,
    name,
    price,
    image,
    pos,
    product_id,
    enabled,
    actual_price,
    image_mini,
    total_units,
    left_units,
    show_pos,
    expiry_date,
    description,
    row,
    custom_css,
  } = product;

  const { maxItems, currencyDetails } = useMachineConfigurationContext();
  const dispatch = useDispatch();
  const notification = useNotificationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );
  //const { checkout } = useSelector((state) => state.product);
  const { totalItems, cart } = useSelector((state) => state.product.checkout);

  /* const linkTo = PATH_DASHBOARD.eCommerce.view(paramCase(name)); */

  const productQuantity = useMemo(() => {
    if (cart.length <= 0) return 0;
    let quantity = 0;
    cart.forEach((product) => {
      if (product.id === id) quantity = product.quantity;
    });
    return quantity;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  let throttling1 = false;
  const handleIncreaseQuantity = () => {
    if (throttling1) {
      return;
    }
    if (productQuantity >= left_units) {
      notification.notify("Not enough stock!");
      return;
    }
    if (totalItems === maxItems) {
      notification.notify("Cart full!");
      return;
    }
    if (totalItems <= maxItems) {
      throttling1 = true;
      handlePress(() => dispatch(increaseQuantity(id)));
      setTimeout(() => {
        throttling1 = false;
      }, 500);
    }
  };

  const handleDecreaseQuantity = () => {
    handlePress(() => dispatch(decreaseQuantity(id)));
  };

  let throttling2 = false;

  const handleAddCart = async () => {
    if (throttling2) {
      return;
    }
    if (productQuantity >= left_units) {
      notification.notify("Not enough stock!");
      return;
    }
    if (totalItems === maxItems) {
      notification.notify("Cart full!");
      return;
    }
    if (totalItems <= maxItems) {
      throttling2 = true;
      handlePress(() =>
        dispatch(
          addToCart({
            id,
            name,
            image,
            price,
            pos,
            product_id,
            enabled,
            actual_price,
            image_mini,
            total_units,
            left_units: left_units - 1,
            show_pos,
            expiry_date,
            description,
            row,
            custom_css,
            quantity: 1,
          })
        )
      );
      setTimeout(() => {
        throttling2 = false;
      }, 500);
    }
  };

  const pressableCardRef = useRef<any>(null);
  const handlePress = (onEnd: () => void) => {
    if (pressableCardRef.current) {
      (pressableCardRef.current as any).handlePress(onEnd);
    }
  };

  return (
    <PressableCard
      cardHeight={80}
      ref={pressableCardRef}
      colorType={
        left_units <= 0 ? CardPrimaryUnSelectedColor : CardPrimaryColor
      }
    >
      <Stack
        direction={"column"}
        sx={{
          marginTop: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "2px 4px",
            width: "100px",
            height: "24px",
          }}
        >
          <div
            style={{
              width: "92px",
              height: "16px",
              fontFamily: "Public Sans",
              fontStyle: "normal",
              fontWeight: "800",
              fontSize: "24px",
              lineHeight: "19px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: THEME.primaryColor,
              padding: "4px",
            }}
            onClick={() => {
              if (left_units > 0) handleAddCart();
            }}
          >
            {show_pos}
          </div>
        </div>
        <div
          style={{
            width: "41.48px",
            height: "24px",
            fontFamily: "Public Sans",
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: "15px",
            lineHeight: "19px",
            display: "flex",
            minWidth: "fit-content",
            justifyContent: "center",
            alignItems: "center",
            color: THEME.fontColor,
            visibility: productQuantity > 0 ? "hidden" : "visible",
          }}
          onClick={() => {
            if (left_units > 0) handleAddCart();
          }}
        >
          {`${
            currencyDetails && currencyDetails.isSuffix
              ? ""
              : currencyDetails?.symbol + " "
          }${fCurrency(price)}${
            currencyDetails && currencyDetails.isSuffix
              ? " " + currencyDetails?.symbol
              : ""
          }`}
        </div>
        <div>
          {productQuantity === 0 ? (
            <Button
              fullWidth
              sx={{
                // borderTop: "0.4px solid rgba(145, 158, 171, 0.24)",
                width: "104px",
                height: "30px",
                color: THEME.primaryColor,
                fontWeight: "700",
                fontSize: "15px",
              }}
              onClick={() => {
                if (left_units > 0) handleAddCart();
              }}
              disabled={left_units <= 0}
            >
              {displayTexts?.products.addProductToCart}
            </Button>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                typography: "body2",
                bgcolor: THEME.primaryColor,
                color: "#FFFFFF",
                width: "104px",
                height: "30px",
              }}
            >
              <IconButton
                size="medium"
                color="inherit"
                onClick={handleDecreaseQuantity}
                sx={{ color: "white", marginLeft: "10px" }}
              >
                <Iconify icon="eva:minus-fill" width={10} />
              </IconButton>
              <Typography fontSize={"15px"} fontWeight={"700"}>
                {productQuantity}
              </Typography>
              <IconButton
                size="medium"
                color="inherit"
                onClick={handleIncreaseQuantity}
                disabled={productQuantity >= total_units}
                sx={{ color: "white", marginRight: "10px" }}
              >
                <Iconify icon="eva:plus-fill" width={10} />
              </IconButton>
            </Stack>
          )}
        </div>
      </Stack>
    </PressableCard>
  );
}
