import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "../../../redux/store";
import VendingVariant from "src/variants/VendingVariant";
import { resetCart } from "../../../redux/slices/product";
import {
  nextVendingItem,
  setVendingStatus,
  resetVendingState,
} from "src/redux/slices/vending";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { SocketEvents } from "src/observers/socketObserver";
import SocketObserver from "src/observers/socketObserver";
import React from "react";
import { SocketContext } from "src/context/socketContext/SocketContext";
import { useSelector } from "../../../redux/store";
import {
  MQTTService,
  OrderService,
  TelegramService,
  VendingService,
} from "src/services";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import {
  TELEGRAM_GROUPS_CHAT_IDS,
  WENDOR_BOTS_TOKENS,
} from "src/services/telegram.service";

// ----------------------------------------------------------------------

const vendedItems: Array<string> = [];
let waitingIntervalId: ReturnType<typeof setTimeout>;

export default function VendingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refetchMachineItemsFetched, machineData, machineConfig } =
    useMachineConfigurationContext();

  const { vendingItems, orderId, vendingStatus, currentItem } = useSelector(
    (state) => state.vending
  );

  const socketObserverInstance = SocketObserver.getInstance();
  const mqttObserverInstance = MQTTObserver.getInstance();

  let successfullyVendedItemsCount = 0;

  const handleOrderItemResponse = (data: any) => {
    const resp = JSON.parse(data);

    if (resp.s) {
      successfullyVendedItemsCount += 1;
    }
    vendedItems.push(data);
    dispatch(nextVendingItem(resp.s));
  };

  const handleLockerOrderItemResponse = (data: any) => {
    console.log("LOCKER RESPONSE", data);

    if (data.s) {
      successfullyVendedItemsCount += 1;
    }
    vendedItems.push(JSON.stringify(data));
    dispatch(nextVendingItem(data.s));

    MQTTService.MQTTService.getInstance().deleteMessage(
      ChannelsOrEvents.LOCKER_ITEM_RESPONSE
    );

    handleOrderStatus("");
  };

  const handleNoItemResponse = async () => {
    try {
      const currentItemVending = vendingItems.filter((item) => {
        return item.bill_item_id === currentItem.bill_item_id;
      });

      const vendItemBody = {
        mid: machineData.machine_id,
        oid: orderId,
        oiid: currentItemVending[0].bill_item_id,
        oir: "VEND_FAILED_NO_ITEM_RESPONSE_FROM_VMC",
        items: currentItemVending[0].position,
        s: false,
      };

      MQTTService.MQTTService.getInstance().deleteMessage(
        ChannelsOrEvents.LOCKER_ITEM_RESPONSE
      );

      vendedItems.push(JSON.stringify(vendItemBody));
      clearTimeout(waitingIntervalId);
      handleOrderStatus("NO_ITEM_RESPONSE");
      dispatch(nextVendingItem(vendItemBody.s));
    } catch (e) {
      console.log(e);
    }
  };

  const handleOrderWithoutVending = async (): Promise<any> => {
    try {
      const itemsVended = vendedItems.map((item) => {
        return JSON.parse(item).oiid;
      });

      const itemsNotVended = vendingItems.filter((item) => {
        return !itemsVended.includes(item.bill_item_id);
      });

      if (itemsNotVended.length > 0) {
        itemsNotVended.map((item) => {
          const vendItemBody = {
            mid: machineData.machine_id,
            oid: orderId,
            oiid: item.bill_item_id,
            oir: "VEND_FAILED_VMC_DISCONNECTED",
            items: item.position,
            s: false,
          };
          vendedItems.push(JSON.stringify(vendItemBody));
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendItemResponses = async () => {
    try {
      let res = null;
      if (machineData.machine_type === "pickupcode") {
        res = await VendingService.setLockerItemResponses(orderId, vendedItems);
      } else {
        res = await VendingService.setVendedItemResponses(orderId, vendedItems);
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrderStatus = (data: any) => {
    TelegramService.sendAlertOnTelegram(
      WENDOR_BOTS_TOKENS.wendor_bot,
      TELEGRAM_GROUPS_CHAT_IDS.vend_started_debugging,
      false,
      {
        orderId: orderId,
        status: data,
      },
      "VMC"
    );
    setTimeout(async () => {
      clearAllSubscriptions();
      dispatch(setVendingStatus());
      dispatch(resetCart());
      console.log(vendedItems);
      const res = await sendItemResponses();
      if (res.isRefund) {
        navigate(PATH_DASHBOARD.eCommerce.refund, {
          state: {
            refundData: res,
            successfullyVendedItemsCount: successfullyVendedItemsCount,
          },
        });
      } else {
        if (successfullyVendedItemsCount === 0) {
          await OrderService.orderFeedback({
            bill_id: orderId,
            value: 0,
          });
          navigate(PATH_DASHBOARD.eCommerce.shop);
        } else {
          navigate(PATH_DASHBOARD.eCommerce.feedback);
        }
      }
    }, 5000);
  };

  const clearAllSubscriptions = () => {
    socketObserverInstance.unsubscribeMultiple(subscriptionIdList.current);
    mqttObserverInstance.unsubscribeMultiple(mqttSubscriptionIdList.current);
  };
  const subscriptionIdList = useRef<Array<string>>([]);
  const mqttSubscriptionIdList = useRef<Array<string>>([]);

  useEffect(() => {
    if (
      vendingItems.length > 0 &&
      Object.keys(currentItem).length > 0 &&
      vendedItems.length <= vendingItems.length
    ) {
      waitingIntervalId = setTimeout(() => {
        handleNoItemResponse();
      }, machineConfig?.vendingPage?.vendItemMaxTime || 60000);
    }

    return () => {
      clearTimeout(waitingIntervalId);
    };
  }, [currentItem]);

  useEffect(() => {
    subscriptionIdList.current.push(
      socketObserverInstance.subscribe(
        SocketEvents.ORDER_ITEM_RESPONSE,
        handleOrderItemResponse
      )
    );
    subscriptionIdList.current.push(
      socketObserverInstance.subscribe(
        SocketEvents.ORDER_STATUS,
        handleOrderStatus
      )
    );

    mqttSubscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.LOCKER_ITEM_RESPONSE,
        handleLockerOrderItemResponse
      )
    );

    return () => {
      refetchMachineItemsFetched();
      clearAllSubscriptions();
      // reset vending state and cart aswell
      // vendedItems.length = 0;
      if (vendingStatus === "IN_PROGRESS") {
        handleOrderWithoutVending();
        // sendItemResponses();
        vendedItems.length = 0;
        dispatch(resetVendingState());
        dispatch(resetCart());
      }
    };
  }, []);

  return (
    <>
      <VendingVariant />
    </>
  );
}
