import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { getCurrentLanguage } from "src/i18n";
import { useSelector } from "src/redux/store";
import { useState } from "react";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";

interface Props {
  elevation: number;
  handleHelpPageClick: () => void;
}

function PageHeaderCrumb({ elevation, handleHelpPageClick }: Props) {
  const [manualReloadTry, setmanualReloadTry] = useState(0);
  const { machineConfiguration } = useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const manualReload = () => {
    if (manualReloadTry >= 4 && navigator.onLine) {
      return window.location.reload();
    }
    return setmanualReloadTry(manualReloadTry + 1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="inherit" elevation={elevation}>
        <Toolbar variant="dense" sx={{ minHeight: "38px", height: "32px" }}>
          <img
            style={{ height: "inherit", width: "auto" }}
            src={
              machineConfiguration.kiosk_display_head_image_url ||
              "/assets/configAssets/AppHeaderLogo.svg"
            }
            onClick={manualReload}
          />
          {machineConfiguration.kiosk_display_head_image_url === null && (
            <Typography fontSize={"13px"} flexGrow={1} marginLeft={"5px"}>
              {displayTexts?.app.title}
            </Typography>
          )}
          {/* <IconButton
            size="small"
            edge={false}
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0, color: "#d80000" }}
            onClick={handleHelpPageClick}
          >
            <HelpIcon />
          </IconButton>
          <Typography fontSize={"10px"} onClick={handleHelpPageClick}>
            {displayTexts?.app.needHelp}
          </Typography> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default PageHeaderCrumb;
