import { VARIANT } from "src/config";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import EcommerceVendingPageVariant2 from "src/pages/dashboard/vending/EcommerceVendingPageVariant2";
import EcommerceVendingPageVariant3 from "src/pages/dashboard/vending/EcommerceVendingPageVariant3";
import LockerVendingPageVariant from "src/pages/dashboard/vending/LockerVendingPageVariant";

function VendingVariant() {
  const { machineData } = useMachineConfigurationContext();

  //switch (VARIANT) {
  switch (machineData.machine_type) {
    case "big":
      return <EcommerceVendingPageVariant3 />;
    case "pickupcode":
      return <LockerVendingPageVariant />;
    default:
      return <EcommerceVendingPageVariant3 />;
  }
}

export default VendingVariant;
