import { Subject } from "rxjs";
import { v4 as uuidv4 } from "uuid";

export enum ChannelsOrEvents {
  MQTT_CONNECTON_STATUS = "MQTT_CONNECTON_STATUS",
  MACHINE_BUSY = "MACHINE_BUSY",
  KIOSK_REFRESH = "KIOSK_REFRESH",
  INIT_RESPONSE = "INIT_RESPONSE",
  HEARTBEAT_RESPONSE = "HEARTBEAT_RESPONSE",
  ORDER_ITEM_RESPONSE = "ORDER_ITEM_RESPONSE",
  ORDER_STATUS = "ORDER_STATUS",
  CASH_INIT_RESPONSE = "CASH_INIT_RESPONSE",
  CASH_RECEIVED = "CASH_RECEIVED",
  CASH_COLLECTION_COMPLETE = "CASH_COLLECTION_COMPLETE",
  RFID_ENABLE_RESPONSE = "RFID_ENABLE_RESPONSE",
  RFID_CARD_DETAILS = "RFID_CARD_DETAILS",
  STRIPE_PAYMENT_COMPLETE = "STRIPE_PAYMENT_COMPLETE",
  ORDER_PICKED = "ORDER_PICKED",
  COIN_RETURNER_DISPENSING = "COIN_RETURNER_DISPENSING",
  COIN_RETURNER_DISPENSED = "COIN_RETURNER_DISPENSED",
  LOCKER_ITEM_RESPONSE = "LOCKER_ITEM_RESPONSE",
}

const InitialValue: Record<string, Subject<any>> = {};

export default class MQTTObserver {
  private static instance: MQTTObserver;

  subjects: Record<string, Subject<any>>;
  subscriberList: Record<string, ReturnType<Subject<any>["subscribe"]>>;

  private constructor() {
    console.log("MQTTObserver Instantiated :: Using RxJS");
    for (const channelOrEvent of Object.keys(ChannelsOrEvents)) {
      InitialValue[channelOrEvent] = new Subject<any>();
    }
    this.subjects = InitialValue;
    this.subscriberList = {};
  }

  public static getInstance() {
    if (!MQTTObserver.instance) {
      MQTTObserver.instance = new MQTTObserver();
    }
    return MQTTObserver.instance;
  }

  subscribe(
    channelOrEvent: ChannelsOrEvents,
    func: (value: any) => void
  ): string {
    const subscriptionId: string = uuidv4();
    const subscription: ReturnType<Subject<any>["subscribe"]> = this.subjects[
      channelOrEvent
    ].subscribe({
      next: func,
    });

    this.subscriberList[subscriptionId] = subscription;

    return subscriptionId;
  }

  subscribeMultiple(
    channelsOrEvents: ChannelsOrEvents[],
    func: (value: any) => void
  ): Array<string> {
    const subscriptionIdList: Array<string> = [];
    channelsOrEvents.forEach((channelOrEvent: ChannelsOrEvents) => {
      const subscriptionId: string = uuidv4();
      const subscription: ReturnType<Subject<any>["subscribe"]> = this.subjects[
        channelOrEvent
      ].subscribe({
        next: func,
      });
      this.subscriberList[subscriptionId] = subscription;
      subscriptionIdList.push(subscriptionId);
    });
    return subscriptionIdList;
  }

  unsubscribe(subscriptionId: string) {
    if (!subscriptionId) return;
    console.log(`MQTTObserver :: unsubscribe :: ${subscriptionId} `);
    this.subscriberList[subscriptionId].unsubscribe();
  }

  unsubscribeMultiple(subscriptionIdList: Array<string>) {
    console.log(
      `MQTTObserver :: unsubscribeMultiple :: ${subscriptionIdList.join(", ")} `
    );
    subscriptionIdList.forEach((subscriptionId: string) => {
      this.subscriberList[subscriptionId].unsubscribe();
    });
  }

  notify(channelOrEvent: ChannelsOrEvents, data: any) {
    console.log(`MQTTObserver :: notify :: ${channelOrEvent} `, data);
    this.subjects[channelOrEvent].next(data);
  }

  end() {
    for (const channelOrEvent of Object.keys(ChannelsOrEvents))
      this.subjects[channelOrEvent].complete();
  }
}
