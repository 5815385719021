import axios, { axiosInstanceV2 } from "../utils/axios";

// * 1.1
async function generateRequestId(
  items: {
    quantity: number;
    pos: string;
  }[]
): Promise<any> {
  try {
    const response = await axios({
      method: "post",
      url: "/api/generateRequestId",
      data: {
        items,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 1.2 V2
async function checkoutRequestV2(data: {
  payment_method: string;
  request_id: string;
  phone?: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/checkoutRequestV2",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.resolve(error);
  }
}

// * 1.3
async function checkStatus(data: {
  payment_method: string;
  request_id: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/checkStatus",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// * 1.3
async function cacelOrder(data: { request_id: string }): Promise<any> {
  try {
    const response = await axios({
      method: "post",
      url: "/api/updateRequestStatus",
      data: {
        ...data,
        status: "cancelled",
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function orderFeedback(data: {
  bill_id: string;
  value: number;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/feedback",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function authoriseSwiftPayment(data: {
  request_id: string;
  phone_number: string;
  otp: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/authoriseSwiftPayment",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function initResponseCashModule(data: {
  requestId: string;
  status: string;
  comment: string;
  code: number;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/initResponseCashModule",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

// *
async function cashTxnUpdateV2(data: {
  amount_added: number;
  requestId: string;
  comment: string;
  note_uuid: string;
  status: string;
  type: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/cashTxnUpdateV2",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}


async function currencyModuleResponse(data: {
  machineId: string;
  requestId: string;
  message: string;
  totalCoinPayout: Array<object>;
  totalCashPayout: Array<object>;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/currencyModuleResponse",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function verifyRFIDdata(data: {
  requestId: string;
  rfidData: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/rfidData",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.resolve(error);
  }
}

async function ezetapCheckoutRequest(data: {
  payment_method: string;
  request_id: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/ezetapCheckoutRequest",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function ezetapCheckStatus(data: {
  payment_method: string;
  request_id: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/ezetapCheckStatus",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function disableEzetapModule(data: {
  requestId: string;
  comment: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/disableEzetapModule",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function authRfidPayment(data: {
  request_id: string;
  pin: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/authoriseRFIDPayment",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function checkoutViaStripePaymentLink(data: {
  request_id: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/checkoutViaStripePaymentLink",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function verifyPickUpCode(data: {
  pickup_code: string;
}): Promise<any> {
  try {
    const response = await axiosInstanceV2({
      method: "post",
      url: "/api/verifyPickUpCode",
      data: {
        ...data,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  generateRequestId,
  checkoutRequestV2,
  checkStatus,
  cacelOrder,
  orderFeedback,
  authoriseSwiftPayment,
  initResponseCashModule,
  cashTxnUpdateV2,
  verifyRFIDdata,
  ezetapCheckoutRequest,
  ezetapCheckStatus,
  disableEzetapModule,
  currencyModuleResponse,
  authRfidPayment,
  checkoutViaStripePaymentLink,
  verifyPickUpCode,
};
