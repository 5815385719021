import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import {
  IVendingState,
  IVendingItem,
  VendingStatus,
} from "../../@types/vending";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialItemState: IVendingItem = {
  position: "",
  show_pos: "",
  product_id: "",
  product_name: "",
  product_description: "",
  image_mini: "",
  image: "",
  price: 0,
  bill_item_id: "",
  vend_status: "",
};

const initialState: IVendingState = {
  activeStep: 0,
  orderId: "",
  vendingItems: [],
  currentItem: { ...initialItemState },
  currentItemIndex: 0,
  vendingStatus: VendingStatus.STARTED,
  rfidData: null,
};

const slice = createSlice({
  name: "vending",
  initialState,
  reducers: {
    setVendingItems(state, action) {
      state.vendingItems = action.payload;
    },
    startVending(state) {
      state.vendingStatus = VendingStatus.IN_PROGRESS;
      state.currentItemIndex = 0;
      state.currentItem = state.vendingItems[0];
    },
    nextVendingItem(state, action) {
      /* console.log(
        "MEOW MOEW",
        action.payload,
        state.vendingItems,
        state.currentItemIndex
      ); */
      const allVendingItems = [...state.vendingItems];
      const currentIndex = state.currentItemIndex;
      const item = { ...allVendingItems[currentIndex] };
      item.vend_status = action.payload;
      allVendingItems[currentIndex] = { ...item };
      state.vendingItems = [...allVendingItems];

      const nextIndex = state.currentItemIndex + 1;
      console.log(nextIndex);

      if (nextIndex >= state.vendingItems.length) {
        state.currentItemIndex = -1;
        state.currentItem = initialItemState;
        return;
      }

      state.currentItemIndex = nextIndex;
      state.currentItem = state.vendingItems[nextIndex];

      //console.log("HOHOHO", state.currentItemIndex, state.currentItem);
    },
    setVendingStatus(state) {
      state.vendingStatus = VendingStatus.COMPLETED;
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    updateVendingStatus(state, action) {},
    resetVendingState(state) {
      state = { ...initialState };
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      const step = action.payload;
      state.activeStep = step;
    },

    updateRfidData(state, action) {
      state.rfidData = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setVendingItems,
  resetVendingState,
  gotoStep,
  backStep,
  nextStep,
  nextVendingItem,
  setVendingStatus,
  updateRfidData,
} = slice.actions;

// ----------------------------------------------------------------------

export function initVending(data: { items: any; orderId: string }) {
  return () => {
    dispatch(slice.actions.setVendingItems(data.items));
    dispatch(slice.actions.setOrderId(data.orderId));
    dispatch(slice.actions.startVending());
  };
}
