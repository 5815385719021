import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getCurrentLanguage } from "src/i18n";
import { COLOR_TEXT_ON_WHITE } from "src/theme/new-ui/component.color";

const InitiatingCashModule = () => {
  const displayTexts = getCurrentLanguage(
    useSelector((state: any) => state.seetings.language).language
  );
  return (
    <Stack direction={"column"} alignItems={"center"} spacing={0} marginTop={3}>
      <Typography fontSize={12} fontWeight={400} color={COLOR_TEXT_ON_WHITE}>
        {displayTexts?.payment.cash.checkingCashMachine}
      </Typography>
      <img src="/assets/configAssets/payment_cash_machine.gif" />
      {/* <CircularProgress color="error" /> */}
    </Stack>
  );
};

export default InitiatingCashModule;
