import { Card, Grid, Stack, Typography } from "@mui/material";

interface Props {
  color?: string;
  status?: string;
  position?: string;
}

const VendingCard = ({ color, status, position }: Props) => {
  return (
    <Grid
      item
      xs={4}
      margin={"inherit"}
      alignItems={"center"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Card
        sx={[
          {
            boxShadow: 3,
            height: "92px",
            width: "88px",
            left: "0px",
            top: "0px",
            borderRadius: "16px",
            backgroundColor: color,
          },
        ]}
      >
        <Card
          sx={[
            {
              boxShadow: 0,
              height: "84px",
              width: "88px",
              left: "0px",
              top: "0px",
              borderRadius: "16px",
              border: status ? "1px solid" : "none",
              borderColor: color,
            },
          ]}
        >
          <Stack
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography
              fontSize={"16px"}
              fontWeight={"700"}
              color={"454F5B"}
              fontFamily={"Public Sans"}
            >
              {position}
            </Typography>
            {status && (
              <Typography
                fontSize={"14px"}
                fontWeight={"700"}
                color={color}
                fontFamily={"Public Sans"}
              >
                {status}
              </Typography>
            )}
          </Stack>
        </Card>
      </Card>
    </Grid>
  );
};

export default VendingCard;
