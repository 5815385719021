import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState, useMemo, useRef } from "react";
import { Container, Box } from "@mui/material";
import { useDispatch, useSelector } from "../../../redux/store";
import {
  initOrderCycle,
  createPaymentMethod,
} from "../../../redux/slices/product";
import {
  setProducts,
  updateInventoryStatus,
} from "../../../redux/slices/product";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { OrderService } from "src/services";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import { getCurrentLanguage } from "src/i18n";
import OverlayLoading from "src/components/overlay-loading/OverlayLoading";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import AuthPickUpCodeForm from "src/sections/auth/AuthPickUpCodeForm";
import LoginLayout from "src/layouts/login/LoginLayout";
import LockerCheckoutOrderComplete from "src/sections/@dashboard/e-commerce/checkout/LockerCheckoutOrderComplete";

export default function PickupCodeVariant() {
  const mqttObserverInstance = MQTTObserver.getInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [authResponse, setAuthResponse]: any = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const {
    products: _products,
    machineConfiguration,
    refreshMachine,
  } = useMachineConfigurationContext();
  const { products: reduxProducts } = useSelector((state) => state.product);
  const { cart } = useSelector((state) => state.product.checkout);

  useEffect(() => {
    dispatch(setProducts(_products));
  }, [dispatch, _products]);

  const products = useMemo(() => reduxProducts, [reduxProducts]);

  const onKioskRefreshRequest = () => {
    if (window.location.pathname === PATH_DASHBOARD.eCommerce.shop) {
      refreshMachine();
    } else {
      dispatch(updateInventoryStatus(true));
    }
  };

  const subscriptionIdList = useRef<Array<string>>([]);

  useEffect(() => {
    subscriptionIdList.current.push(
      mqttObserverInstance.subscribe(
        ChannelsOrEvents.KIOSK_REFRESH,
        onKioskRefreshRequest
      )
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{displayTexts?.products.helmetTitle}</title>
      </Helmet>
      <>
        <Container maxWidth={false} disableGutters>
          {isLoading && <OverlayLoading infoText={infoText} />}
          <PageHeaderCrumb
            elevation={10}
            handleHelpPageClick={() => {
              console.log("handleHelpPageClick");
            }}
          />
          <LoginLayout showLogo={false}>
            <AuthPickUpCodeForm setAuthResponse={setAuthResponse} />
          </LoginLayout>
          <Box sx={{ marginBottom: "30px" }}>
            <PageFooterCrumb elevation={0} />
          </Box>
          {authResponse?.error === false ? (
            <LockerCheckoutOrderComplete open={true} requestId={authResponse.data.is_request_raised.id} />
          ) : null}
        </Container>
      </>
    </>
  );
}
