import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "src/redux/store";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import { fCurrency } from "src/utils/formatNumber";
import { resetCart } from "src/redux/slices/product";
import PressableButton from "src/components/pressables/PressableButton";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { getCurrentLanguage } from "src/i18n";

interface Props {
  positiveAction: () => void;
  negativeAction: () => void;
}

function PageFooterAction({ positiveAction, negativeAction }: Props) {
  const dispatch = useDispatch();
  const { currencyDetails } = useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { products, checkout } = useSelector((state) => state.product);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          top: "auto",
          bottom: 38,
          padding: "10px",
        }}
      >
        <Stack
          flexShrink={"unset"}
          direction={"row"}
          justifyContent={"space-between"}
          minHeight={"40px"}
        >
          {checkout.totalItems != 0 && (
            <PressableButton
              text={displayTexts?.products.clearCart}
              onClick={() => dispatch(resetCart())}
            />
          )}

          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              marginLeft: "auto",
            }}
          >
            {checkout.totalItems != 0 && (
              <Stack direction={"column"} marginRight={"10px"}>
                <Typography fontSize={"10px"}>
                  {displayTexts?.products.itemsInCart.replace(
                    "{{items}}",
                    `${checkout.totalItems}`
                  )}
                </Typography>
                <Typography textAlign={"right"} fontWeight={"700"}>
                  {`${currencyDetails && currencyDetails.isSuffix
                    ? ""
                    : currencyDetails?.symbol + " "
                    }${fCurrency(checkout.total || 0)}${currencyDetails && currencyDetails.isSuffix
                      ? " " + currencyDetails?.symbol
                      : ""
                    }`}
                </Typography>
              </Stack>
            )}
            <PressableButton
              isFilled
              disabled={checkout.totalItems == 0}
              startIcon={
                <ShoppingCartIcon fontSize="small" sx={{ color: "#fff" }} />
              }
              text={displayTexts?.products.checkout}
              onClick={() => positiveAction()}
            />
          </Stack>
        </Stack>
        <PageFooterCrumb elevation={0} />
      </AppBar>
    </Box>
  );
}

export default PageFooterAction;
