import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { getCurrentLanguage } from "src/i18n";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { useSelector } from "src/redux/store";
import { openServiceMode } from "src/services/machineConfiguration.service";

interface Props {
  elevation: number;
}

function PageFooterCrumb({ elevation }: Props) {
  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { machineConfiguration } = useMachineConfigurationContext();

  const [serviceModeState, setServiceModeState] = useState({
    isError: false,
    errorMsg: "",
  });

  const [reload, setReload] = useState(0);

  const handleOpenningOfServiceMode = async () => {
    if (reload >= 4 && navigator.onLine) {
      const res = await openServiceMode();

      setServiceModeState({
        isError: res.isError,
        errorMsg: res.errorMsg,
      });
    }
    return setReload(reload + 1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color="inherit"
        elevation={elevation}
        position="fixed"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar
          variant="dense"
          sx={{
            minHeight: "38px",
            height: "24px",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={"10px"} marginRight={"5px"}>
            {displayTexts?.app.poweredBy}
          </Typography>
          <img
            style={{ height: "inherit", width: "auto" }}
            src={
              machineConfiguration.kiosk_display_show_footer === true
                ? machineConfiguration.kiosk_display_head_image_url ||
                  "/assets/configAssets/AppHeaderLogo.svg"
                : "/assets/configAssets/AppHeaderLogo.svg"
            }
            onClick={handleOpenningOfServiceMode}
          />
          {machineConfiguration.kiosk_display_head_image_url === null && (
            <Typography fontSize={"10px"} marginLeft={"5px"}>
              {displayTexts?.app.title}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default PageFooterCrumb;
