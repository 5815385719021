import { Box, Button, Card } from "@mui/material";
import { useState } from "react";
import { THEME } from "src/theme/globalTheme";
import IColorType from "src/theme/new-ui/color.schema";
import generateAlpha from "src/theme/new-ui/color.utils";

interface Props {
  index: number;
  isStart: boolean;
  isEnd: boolean;
  borderRadius: number;
  fontSize: number;
  tab: { tabTitle: string | undefined };
  tabHeight?: number;
  shadowHeight?: number;
  colorType: IColorType;
  onClick: (index: number) => void;
  selected?: boolean;
}

const ANIM_DURATION_MS = 60;

const PressableTabItem = (
  {
    index,
    isStart = false,
    isEnd = false,
    borderRadius,
    fontSize,
    tab,
    tabHeight = 40,
    shadowHeight = 4,
    colorType,
    onClick,
    selected,
  }: Props,
  ref: any
) => {
  const [marginTop, setMarginTop] = useState(0);
  const [height, setHeight] = useState(tabHeight + shadowHeight);

  const handlePress = () => {
    setMarginTop(shadowHeight);
    setHeight(tabHeight);
    setTimeout(() => {
      setMarginTop(0);
      setHeight(tabHeight + shadowHeight);
      setTimeout(() => {
        onClick(index);
      }, 50);
    }, ANIM_DURATION_MS);
  };

  return (
    <Box key={`${index}`} flex={1}>
      <Card
        sx={[
          {
            boxShadow: 1,
            marginTop: `${marginTop}px`,
            height: `${height}px`,
            transition: `margin-top ${ANIM_DURATION_MS}ms linear 0s, height ${ANIM_DURATION_MS}ms linear 0s`,
            marginLeft: "6px",
            left: "0px",
            top: "0px",
            borderTopLeftRadius: `${isStart ? borderRadius : 0}px`,
            borderTopRightRadius: `${isEnd ? borderRadius : 0}px`,
            borderBottomLeftRadius: `${isStart ? borderRadius : 0}px`,
            borderBottomRightRadius: `${isEnd ? borderRadius : 0}px`,
            backgroundColor: selected
              ? `${THEME.primaryShade}`
              : generateAlpha(THEME.disabledColor, 0.3),
          },
        ]}
      >
        <Card
          sx={[
            {
              boxShadow: 0,
              height: `${tabHeight}px`,
              left: "0px",
              top: "0px",
              borderTopLeftRadius: `${isStart ? borderRadius : 0}px`,
              borderTopRightRadius: `${isEnd ? borderRadius : 0}px`,
              borderBottomLeftRadius: `${isStart ? borderRadius : 0}px`,
              borderBottomRightRadius: `${isEnd ? borderRadius : 0}px`,
              backgroundColor: selected
                ? `${THEME.primaryColor}`
                : `${THEME.secondaryColor}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Button
            fullWidth
            variant="text"
            sx={{
              minWidth: "96px",
              textTransform: "none",
              fontSize: `${fontSize}px`,
              fontWeight: "700",
              color: `${colorType.text}`,
            }}
            onClick={handlePress}
          >
            {tab.tabTitle}
          </Button>
        </Card>
      </Card>
    </Box>
  );
};

export default PressableTabItem;
