function getTopicsToSubscribe(data: {
  multivac_version: string;
  machine_id: string;
  rfid_version: string;
  coin_changer_version: string;
}): CHANNELS {
  const {
    multivac_version: multivacVersion,
    machine_id: machineId,
    rfid_version: rfidVersion,
    coin_changer_version: coinChangerVersion,
  } = data;

  const channels = {
    machineBusy: `/${multivacVersion}/${machineId}/machineBusy`,
    kioskRefresh: `KIOSK/${multivacVersion}/${machineId}/KIOSKUPDATE`,
    initRespone: `VMC/${multivacVersion}/${machineId}/INIT_RESPONSE`,
    heartBeatResponse: `VMC/${multivacVersion}/${machineId}/HEARTBEAT_RESPONSE`,
    orderItemResponse: `VMC/${multivacVersion}/${machineId}/ORDER_ITEM_RESPONSE`,
    orderStatus: `VMC/${multivacVersion}/${machineId}/ORDER_STATUS`,
    cashInitResponse: `CASH/${multivacVersion}/${machineId}/INIT_RESPONSE`,
    cashReceived: `CASH/${multivacVersion}/${machineId}/CASH_RECEIVED`,
    cashCollectionComplete: `CASH/${multivacVersion}/${machineId}/CASH_COLLECTION_COMPLETED`,
    rfidEnableResponse: `RFID/${rfidVersion}/${machineId}/ENABLED`,
    rfidCardDetails: `RFID/${rfidVersion}/${machineId}/RFID_DATA`,
    stripePaymentComplete: `SERVER/${machineId}/PAYMENT_COMPLETE`,
    orderPicked: `VMC/${multivacVersion}/${machineId}/ORDER_PICKED`,
    coinReturnerDispensing: `COIN/${coinChangerVersion}/${machineId}/STARTING_DISPENSING`,
    coinReturnerDispensed: `COIN/${coinChangerVersion}/${machineId}/CASH_DISPENSED`,
    lockerItemResponse: `LOCKER_RESPONSE/${machineId}`,
  };

  return channels;
}

export default getTopicsToSubscribe;

export type CHANNELS = {
  machineBusy: string;
  kioskRefresh: string;
  initRespone: string;
  heartBeatResponse: string;
  orderItemResponse: string;
  orderStatus: string;
  cashInitResponse: string;
  cashReceived: string;
  cashCollectionComplete: string;
  rfidEnableResponse: string;
  rfidCardDetails: string;
  stripePaymentComplete: string;
  orderPicked: string;
  coinReturnerDispensing: string;
  coinReturnerDispensed: string;
  lockerItemResponse: string;
};
