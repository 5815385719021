import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Container, Stack, Typography } from "@mui/material";
// routes
// redux
import { useDispatch, useSelector } from "../../../redux/store";
// sections
import { OrderService } from "src/services";
import { PATH_AFTER_LOGIN } from "src/config";
import PageHeaderCrumb from "src/sections/@dashboard/crumbs/variant1/PageHeaderCrumb";
import { getCurrentLanguage } from "src/i18n";
import PageFooterCrumb from "src/sections/@dashboard/crumbs/variant1/PageFooterCrumb";
import { COLOR_GRAY } from "src/theme/new-ui/component.color";
//import FeedbackVendingMaching from "src/assets/feedback/feedback_screen.gif";
import PressableFeedback from "src/components/pressables/PressableFeedback";
import { resetVendingState } from "src/redux/slices/vending";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { TIMEOUT_DEFAULT_VALUES } from "src/constants/index";
import QRCode from "qrcode.react";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
let feedbackTimeoutID: ReturnType<typeof setTimeout>;

export default function EcommerceFeedbackPageVariant1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let time = new Date().toLocaleTimeString("en-US", { hour12: true });

  const { machineConfiguration, machineData } =
    useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state) => state.seetings.language).language
  );

  const { orderId } = useSelector((state) => state.vending);

  const handelFeedbackButtonsClick = async (value: number, bill_id: string) => {
    const response = await OrderService.orderFeedback({
      bill_id,
      value,
    });
    if (!response.error) {
      clearSubscriptions();
      navigate(PATH_AFTER_LOGIN);
    }
  };

  const clearSubscriptions = () => {
    console.log("hello EcommerceFeedbackPageVariant1");
    clearTimeout(feedbackTimeoutID);
  };

  useEffect(() => {
    dispatch(resetVendingState());
    feedbackTimeoutID = setTimeout(() => {
      navigate(PATH_AFTER_LOGIN);
    }, parseInt(machineConfiguration.kiosk_feedback_timeout ? machineConfiguration.kiosk_feedback_timeout : TIMEOUT_DEFAULT_VALUES.kiosk_feedback_timeout) * 1000);
    return clearSubscriptions;
  }, []);

  return (
    <>
      <Helmet>
        <title>{displayTexts?.vend.helmetTitle}</title>
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
        }}
      >
        <PageHeaderCrumb
          elevation={10}
          handleHelpPageClick={() => {
            console.log("handleHelpPageClick");
          }}
        />

        <Stack
          sx={{
            display: "flex",
            marginTop: "40px",
            alignItems: "center",
          }}
          spacing={0}
        >
          <Typography
            marginTop={3}
            variant={"h6"}
            fontSize={18}
            fontWeight={700}
            lineHeight={"28px"}
            align="center"
            color={"#454F5B"}
            sx={{
              height: "36px",
              width: "400px",
              left: "54px",
              top: "99px",
              "border-radius": "nullpx",
            }}
          >
            {displayTexts?.vend.supportText}{" "}
            {machineConfiguration.kiosk_support_contact ||
              displayTexts?.vend.supportPhoneNumber}
          </Typography>
          <Stack mt={"38px"}>
            <QRCode
              value={`https://wa.me/${
                machineConfiguration.kiosk_support_contact ||
                displayTexts?.vend.supportPhoneNumber
              }?text=Machine+ID:+${
                machineData.machine_id
              }%0aOrder+ID:+${orderId}%0aTime:+${time}%0aFeedback+regarding:+`}
              includeMargin={false}
            />
          </Stack>
          <Typography
            marginTop={5}
            fontSize={16}
            fontWeight={400}
            lineHeight={"24px"}
            align="center"
            color={"#454F5B"}
            textOverflow="false"
            sx={{
              height: "36px",
              left: "0.5px",
              top: "438px",
              "border-radius": "nullpx",
            }}
          >
            {displayTexts?.vend.rateExperience}
          </Typography>

          <Stack
            marginTop={3}
            direction={"row"}
            width={"100%"}
            paddingLeft={5}
            paddingRight={5}
            justifyContent={"space-around"}
          >
            <PressableFeedback
              emoji="/assets/configAssets/img_horrible.svg"
              onClick={() => {
                handelFeedbackButtonsClick(0, orderId);
              }}
            />
            <PressableFeedback
              emoji="/assets/configAssets/img_amazing.svg"
              onClick={() => {
                handelFeedbackButtonsClick(5, orderId);
              }}
            />
          </Stack>
          <Stack
            marginTop={2}
            direction={"row"}
            width={"100%"}
            paddingLeft={5}
            paddingRight={5}
            justifyContent={"space-around"}
          >
            <Typography
              fontSize={14}
              fontWeight={700}
              lineHeight={"24px"}
              align="center"
              color={"#454F5B"}
            >
              {displayTexts?.vend.horrible}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={700}
              lineHeight={"24px"}
              align="center"
              color={"#454F5B"}
            >
              {displayTexts?.vend.amazing}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            position={"fixed"}
            sx={{ bottom: "40px" }}
            fontSize={14}
            fontWeight={600}
            color={COLOR_GRAY}
          >
            {displayTexts?.payment.orderId.replace("{{orderId}}", orderId)}
          </Typography>
          <PageFooterCrumb elevation={10} />
        </Box>
      </Container>
    </>
  );
}
