import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";

import {
  ActionMapType,
  MachineConfigContextType,
  MachineConfigurationStateType,
  ActionTypes,
  CurrencyDetailsType,
} from "./types";

import { MachineConfigService, MQTTService } from "../services";
import MachineConfiguration from "./MachineConfiguration";
import { useAuthContext } from "src/auth/useAuthContext";
import MQTTObserver, { ChannelsOrEvents } from "../observers/mqttObserver";
import { useNavigate } from "react-router";
import { PATH_AFTER_LOGIN } from "src/config";
import { PATH_DASHBOARD } from "src/routes/paths";
import SocketObserver, { SocketEvents } from "../observers/socketObserver";
import { SocketClient } from "src/services/socket.service";
import { MACHINE_TYPE_SETTINGS } from "src/constants";
import { COLOR_PRIMARY } from "src/theme/new-ui/component.color";
import { theme } from "../theme/globalTheme";

type Payload = {
  [ActionTypes.INITIAL]: {
    isLoading: boolean;
    isSuccessful: boolean;
    isFailed: boolean;
    isMachineValidated: Boolean;
    configuration: any;
    enabledPaymentGateways: any;
    currencyDetails?: CurrencyDetailsType | null;
    maxItems: number;
    machineData: any;
    machineTheme: any;
  };
  [ActionTypes.REFRESH]: {
    revokeAllSubscriptions: Function;
  };
  [ActionTypes.SET_CONFIG_DATA]: {
    isLoading: boolean;
    isSuccessful: boolean;
    isFailed: boolean;
    isMachineValidated: boolean;
    configuration: any;
    enabledPaymentGateways: any;
    currencyDetails?: CurrencyDetailsType | null;
    maxItems: number;
    machineData: any;
    machineTheme: any;
  };
  [ActionTypes.MACHINE_VALIDATED]: {
    isLoading: boolean;
    isFailed: boolean;
    isMachineValidated: boolean;
    configuration: any;
    enabledPaymentGateways: any;
    currencyDetails?: CurrencyDetailsType | null;
    maxItems: number;
    machineData: any;
    machineTheme: any;
  };
  [ActionTypes.MQTT_CONNECTED]: {
    isMqttConnected: boolean;
  };
  [ActionTypes.VMC_CONNECTED]: {
    isVMCConnected: boolean;
  };
  [ActionTypes.VMC_DISCONNECTED]: {
    isVMCConnected: boolean;
  };
  [ActionTypes.PRODUCTS_FETCHED]: {
    isProductsFetched: boolean;
    products: any;
  };
  [ActionTypes.SET_CONFIGURATION_STATUS]: {
    isSuccessful: boolean;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const machineConfigurationInitialState: MachineConfigurationStateType = {
  isLoading: true,
  isSuccessful: false,
  isFailed: false,
  isMachineValidated: false,
  isMqttConnected: false,
  isVMCConnected: false,
  isProductsFetched: false,
  products: [],
  configuration: {},
  enabledPaymentGateways: {},
  currencyDetails: null,
  maxItems: 0,
  machineData: {},
  machineConfig: MACHINE_TYPE_SETTINGS,
  machineTheme: {},
};

const reducer = (state: MachineConfigurationStateType, action: ActionsType) => {
  if (action.type === ActionTypes.INITIAL) {
    return {
      ...state,
      isLoading: true,
      isSuccessful: false,
      isFailed: false,
      isMachineValidated: false,
      configuration: {},
      enabledPaymentGateways: {},
      currencyDetails: null,
      maxItems: 0,
      machineData: {},
      machineTheme: {},
    };
  }
  if (action.type === ActionTypes.SET_CONFIG_DATA) {
    return {
      ...state,
      isLoading: true,
      isSuccessful: false,
      configuration: action.payload.configuration,
      enabledPaymentGateways: action.payload.enabledPaymentGateways,
      currencyDetails: action.payload.currencyDetails,
      maxItems: action.payload.maxItems,
      machineData: action.payload.machineData,
    };
  }
  if (action.type === ActionTypes.MACHINE_VALIDATED) {
    return {
      ...state,
      isLoading: true,
      isMachineValidated: action.payload.isMachineValidated,
      ifFailed: action.payload.isFailed,
      configuration: action.payload.configuration,
      enabledPaymentGateways: action.payload.enabledPaymentGateways,
      currencyDetails: action.payload.currencyDetails,
      maxItems: action.payload.maxItems,
      machineData: action.payload.machineData,
    };
  }
  if (action.type === ActionTypes.MQTT_CONNECTED) {
    return {
      ...state,
      isMqttConnected: action.payload.isMqttConnected,
    };
  }
  if (action.type === ActionTypes.VMC_CONNECTED) {
    return {
      ...state,
      isVMCConnected: action.payload.isVMCConnected,
    };
  }
  if (action.type === ActionTypes.VMC_DISCONNECTED) {
    return {
      ...state,
      isVMCConnected: action.payload.isVMCConnected,
    };
  }
  if (action.type === ActionTypes.PRODUCTS_FETCHED) {
    return {
      ...state,
      isProductsFetched: action.payload.isProductsFetched,
      products: action.payload.products,
    };
  }
  if (action.type === ActionTypes.SET_CONFIGURATION_STATUS) {
    return {
      ...state,
      isSuccessful: action.payload.isSuccessful,
    };
  }
  if (action.type === ActionTypes.REFRESH) {
    action.payload.revokeAllSubscriptions();
    window.location.reload();
  }
  return state;
};

// ----------------------------------------------------------------------

export const MachineConfigurationContext =
  createContext<MachineConfigContextType | null>(null);

// ----------------------------------------------------------------------

type MachineConfigurationProviderProps = {
  children: React.ReactNode;
};

export function MachineConfigurationProvider({
  children,
}: MachineConfigurationProviderProps) {
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const [wait15Secs, setWait15Secs] = useState(true); // TODO: Give better name to timers. Should tell what it is for
  const [wait60Secs, setWait60Secs] = useState(true); // TODO: Give better name to timers. Should tell what it is for

  const [state, dispatch] = useReducer(
    reducer,
    machineConfigurationInitialState
  );
  console.log("In MachineConfigurationProvider", state.isVMCConnected);

  let mqttConnectStatusSubscriberId: string, vmcInitSubscriberId: string;

  useEffect(() => {
    let wait15SecsId: ReturnType<typeof setTimeout>;
    console.log("wait15Secs");
    if (wait15Secs) {
      wait15SecsId = setTimeout(() => {
        setWait15Secs(false);
      }, 15000);
    }
    return () => {
      clearTimeout(wait15SecsId);
    };
  }, [wait15Secs]);

  useEffect(() => {
    console.log("wait60Secs", wait60Secs);

    const wait60SecsId = setTimeout(() => {
      setWait60Secs(false);
    }, 60000);
    return () => {
      clearTimeout(wait60SecsId);
    };
  }, [wait60Secs]);

  const mqttObserverHandler = (data: any) => {
    //console.log("mqttObserverInstance :: ", data);
    dispatch(data);
  };

  const vmcObserverHandler = (data: any) => {
    if (data.status) {
      dispatch({
        type: ActionTypes.VMC_CONNECTED,
        payload: {
          isVMCConnected: data.status,
        },
      });
    } else {
      dispatch({
        type: ActionTypes.VMC_DISCONNECTED,
        payload: {
          isVMCConnected: data.status,
        },
      });
    }
  };

  const mqttObserverInstance: MQTTObserver = MQTTObserver.getInstance();
  const socketObserverInstance = SocketObserver.getInstance();

  const initialize = useCallback(async () => {
    try {
      const accessToken =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : ""; // TODO: Move to utils

      if (accessToken) {
        const response = await MachineConfigService.machineValidate();
        console.log(
          `MachineConfigurationContext :: MachineConfigService.machineValidate() ::`,
          response
        );

        if (!response.error) {
          theme(response.machineTheme);
          dispatch({
            type: ActionTypes.MACHINE_VALIDATED,
            payload: {
              isLoading: true,
              isFailed: false,
              isMachineValidated: true,
              configuration: response.configuration,
              enabledPaymentGateways: response.enabled_payment_gateways,
              currencyDetails: response.currency_details,
              maxItems: response.maxItems,
              machineData: response.data,
              machineTheme: response.machineTheme,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.MACHINE_VALIDATED,
            payload: {
              isLoading: false,
              isFailed: true,
              isMachineValidated: false,
              configuration: {},
              enabledPaymentGateways: {},
              currencyDetails: null,
              maxItems: 0,
              machineData: {},
              machineTheme: {},
            },
          });
        }
      } else {
        logout();
      }
    } catch (error) {
      console.error(error); // TODO: Handle Error
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (state.isMachineValidated) {
      MQTTService.MQTTService.init(state.machineData, true);

      mqttConnectStatusSubscriberId = mqttObserverInstance.subscribe(
        ChannelsOrEvents.MQTT_CONNECTON_STATUS,
        mqttObserverHandler
      );

      // vmcInitSubscriberId = mqttObserverInstance.subscribe(
      //   ChannelsOrEvents.INIT_RESPONSE,
      //   vmcObserverHandler
      // );
      vmcInitSubscriberId = socketObserverInstance.subscribe(
        SocketEvents.SOCKET_STATUS,
        vmcObserverHandler
      );

      SocketClient.init(state.machineData?.socket_url);
    }
    return () => {
      console.log({ mqttConnectStatusSubscriberId, vmcInitSubscriberId });
      socketObserverInstance.unsubscribe(vmcInitSubscriberId);
      mqttObserverInstance.unsubscribe(mqttConnectStatusSubscriberId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isMachineValidated]);

  // MACHINE REFRESH
  const refreshMachine = async (hardRefreshFlag?: boolean | undefined) => {
    // TODO :: Unsuscribe to all subscriptions.
    if (hardRefreshFlag) window.location.reload();

    dispatch({
      type: ActionTypes.REFRESH,
      payload: {
        revokeAllSubscriptions: revokeAllSubscriptionsCallback,
      },
    });
  };

  const revokeAllSubscriptionsCallback = () => {
    mqttObserverInstance.end();
    MQTTService.MQTTService.getInstance()?.disconnect();
  };

  useEffect(() => {
    if (
      state.isMachineValidated &&
      state.isVMCConnected &&
      state.isProductsFetched
    ) {
      dispatch({
        type: ActionTypes.SET_CONFIGURATION_STATUS,
        payload: {
          isSuccessful: true,
        },
      });
      navigate(PATH_AFTER_LOGIN, { replace: true });
    } else {
      dispatch({
        type: ActionTypes.SET_CONFIGURATION_STATUS,
        payload: {
          isSuccessful: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.isMachineValidated,
    state.isVMCConnected,
    state.isProductsFetched,
  ]);

  const fetchItems = () => {
    MachineConfigService.fetchMachineItems()
      .then((response) => {
        if (!response.error) {
          dispatch({
            type: ActionTypes.PRODUCTS_FETCHED,
            payload: {
              isProductsFetched: true,
              products: response.data,
            },
          });
        }
      })
      .catch((error) => {
        // TODO: Handle Error
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <MachineConfigurationContext.Provider
      value={{
        isLoading: state.isLoading,
        isSuccessful: state.isSuccessful,
        machineConfiguration: state.configuration,
        enabledPaymentGateways: state.enabledPaymentGateways,
        currencyDetails: state.currencyDetails,
        maxItems: state.maxItems,
        machineData: state.machineData,
        products: state.products,
        refreshMachine: refreshMachine,
        refetchMachineItemsFetched: fetchItems,
        machineConfig: state.machineConfig,
        machineTheme: state.machineTheme,
      }}
    >
      {state.isSuccessful ? (
        <>{children}</>
      ) : (
        <MachineConfiguration
          title="Configuring Machine"
          subheader="Syncing System Configuration"
          wait15Secs={wait15Secs}
          dispatch={dispatch}
          dispatchRefresh={{
            type: ActionTypes.REFRESH,
            payload: {
              revokeAllSubscriptions: revokeAllSubscriptionsCallback,
            },
          }}
          setWait15Secs={setWait15Secs}
          wait60Secs={wait60Secs}
          chart={{
            series: [
              {
                label: "Machine Configuration",
                value: state.isMachineValidated ? 25 : 0,
              },
              {
                label: "VMC Connection",
                value: state.isVMCConnected ? 25 : 0,
              },
              {
                label: "Fetching Products",
                value: state.isProductsFetched ? 25 : 0,
              },
            ],
          }}
        />
      )}
    </MachineConfigurationContext.Provider>
  );
}
