import { VARIANT } from "src/config";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import EcommerceFeedbackPageVariant1 from "src/pages/dashboard/feedback/EcommerceFeedbackPageVariant1";
import EcommerceFeedbackPageVariant2 from "src/pages/dashboard/feedback/EcommerceFeedbackPageVariant2";

function FeedbackVariant() {
  const { machineData } = useMachineConfigurationContext();

  //switch (VARIANT) {
  switch (machineData.machine_type) {
    case "big":
      return <EcommerceFeedbackPageVariant1 />;
    default:
      return <EcommerceFeedbackPageVariant1 />;
  }
}

export default FeedbackVariant;
