// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  /*   one: path(ROOTS_DASHBOARD, "/one"),
  two: path(ROOTS_DASHBOARD, "/two"),
  three: path(ROOTS_DASHBOARD, "/three"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    four: path(ROOTS_DASHBOARD, "/user/four"),
    five: path(ROOTS_DASHBOARD, "/user/five"),
    six: path(ROOTS_DASHBOARD, "/user/six"),
  }, */
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    /*  list: path(ROOTS_DASHBOARD, "/e-commerce/list"), */
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    vend: path(ROOTS_DASHBOARD, "/e-commerce/vend"),
    feedback: path(ROOTS_DASHBOARD, "/e-commerce/feedback"),
    refund: path(ROOTS_DASHBOARD, "/e-commerce/refund"),
    screensaver: path(ROOTS_DASHBOARD, "/e-commerce/screensaver"),
    /* new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) =>
      path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ), */
  },
};
