import generateAlpha from "./new-ui/color.utils";

export const THEME = {
  primaryColor: "#d80000",
  primaryShade: generateAlpha("#d80000", 0.3),
  secondaryColor: "white",
  screenResolution: null,
  font: "sans-serif",
  fontColor: "#333",
  themeName: "Default Wendor Theme",
  disabledColor: "#919eab",
  baseColor: "#c4cdd5",
};

export function theme(themeObject: any) {
  if (themeObject !== null && themeObject !== undefined) {
    THEME.primaryColor = themeObject.primary_color;
    THEME.primaryShade = generateAlpha(themeObject.primary_color, 0.3);
    THEME.secondaryColor = themeObject.secondary_color;
    THEME.screenResolution = themeObject.screen_resolution;
    THEME.font = themeObject.font;
    THEME.fontColor = themeObject.font_color;
    THEME.themeName = themeObject.theme_name;
    THEME.disabledColor = themeObject.disabled_color;
    THEME.baseColor = themeObject.base_color;
  }
}
