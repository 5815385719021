import { ApexOptions } from "apexcharts";
// @mui
import { useTheme, alpha } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  Stack,
  Box,
  Button,
  Typography,
  CardProps,
  Grid,
  Theme,
  LinearProgress,
} from "@mui/material";
// utils
import { fNumber } from "../utils/formatNumber";
// components
import Chart, { useChart } from "../components/chart";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { MachineConfigService } from "src/services";
import Image from "src/components/image/Image";
import { openServiceMode } from "src/services/machineConfiguration.service";

// ----------------------------------------------------------------------

type ItemProps = {
  label: string;
  value: number;
};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  wait15Secs: boolean;
  dispatch: any;
  dispatchRefresh: any;
  setWait15Secs: Dispatch<SetStateAction<boolean>>;
  wait60Secs: boolean;
  chart: {
    colors?: string[];
    series: ItemProps[];
    options?: ApexOptions;
  };
}

export default function MachineConfiguration({
  title,
  subheader,
  chart,
  wait15Secs,
  dispatch,
  dispatchRefresh,
  setWait15Secs,
  wait60Secs,
}: Props) {
  const { colors, series: _series, options } = chart;
  const total = 100;

  const [disableTryAgain, setDisableTryAgain] = useState(false);

  const [manualReloadTry, setmanualReloadTry] = useState(0);

  const manualReload = () => {
    if (manualReloadTry >= 4 && navigator.onLine) {
      return localStorage.clear();
    }
    return setmanualReloadTry(manualReloadTry + 1);
  };

  const theme = useTheme();

  const [series, setSeries] = useState(0);
  const chartSeries = useRef(0);

  useEffect(() => {
    const percent =
      (_series.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        0
      ) /
        total) *
      100;
    setSeries(percent);
    chartSeries.current = percent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_series]);

  //(series.filter((i) => i.label === "Sold out")[0].value / total) * 100;

  const chartColors = colors || [
    theme.palette.primary.light,
    theme.palette.primary.main,
  ];

  const chartOptions = useChart({
    legend: {
      show: false,
    },
    grid: {
      padding: { top: -32, bottom: -32 },
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [chartColors].map((colors) => [
          { offset: 0, color: colors[0] },
          { offset: 100, color: colors[1] },
        ]),
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: "Completed",
            formatter: () => `${fNumber(chartSeries.current)}%`,
          },
        },
      },
    },
    ...options,
  });

  const handelTryAgain = () => {
    console.log("TRY AGAIN" /* customerInfo?.id */);
    setWait15Secs(true);
    setDisableTryAgain(true);
    setTimeout(() => {
      setDisableTryAgain(false);
    }, 4000);
    // MachineConfigService.checkVMC();
  };

  return (
    <>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 8 }} />
      {wait15Secs ? (
        <Chart
          type="radialBar"
          series={[series]}
          options={chartOptions}
          height={310}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            /* key={paymentImage} */
            alt="Machine Error"
            src="/assets/configAssets/158_3772_2193_47-P0V_B16-493.jpg"
            sx={{
              width: "280px",
            }}
          />
        </div>
      )}

      <Stack spacing={2} sx={{ p: 5 }}>
        {_series.map((item, index) => (
          <Legend
            key={`${item.label}_${index}`}
            item={item}
            theme={theme}
            wait15Secs={wait15Secs}
            wait60Secs={wait60Secs}
          />
        ))}
      </Stack>
      {!wait15Secs ? (
        <Grid container spacing={2} p={"10px"}>
          <Grid item xs={6} md={3}>
            <Button
              disabled={disableTryAgain}
              fullWidth
              color="success"
              variant="contained"
              /* startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} */
              onClick={handelTryAgain}
            >
              Try Again
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              color="error"
              variant="contained"
              /* startIcon={<Iconify icon="eva:close-circle-fill" />} */
              onClick={() => {
                openServiceMode();
              }}
            >
              Go Offline
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              color="info"
              variant="contained"
              /* startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} */
              onClick={manualReload}
            >
              Check For Refill
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              color="info"
              variant="contained"
              /* startIcon={<Iconify icon="eva:close-circle-fill" />} */
              onClick={() => {
                console.log("REFRESH" /* customerInfo?.id */);
                dispatch(dispatchRefresh);
              }}
            >
              Refresh
            </Button>
          </Grid>
          <Grid item xs={2} md={9.5}></Grid>
          <Grid item xs={10} md={2.5}>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              Contact Wendor Support if the issue persists.
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

// ----------------------------------------------------------------------

type LegendProps = {
  item: ItemProps;
  theme: Theme;
  wait15Secs: boolean;
  wait60Secs: boolean;
};

function Legend({ item, theme, wait15Secs, wait60Secs }: LegendProps) {
  const [progress, setProgress] = useState(0);

  const [buffer, setBuffer] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const progressRef = useRef(() => {});

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16),
            borderRadius: 0.75,
            ...(item.value === 25 && {
              bgcolor: /* "primary.main" */ "#00AB55",
            }),
          }}
        />

        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
          {item.label}
        </Typography>
      </Stack>

      {item.value === 25 ? (
        <Typography
          variant="subtitle1"
          sx={{ color: /* `${theme.palette.primary.main}` */ "#00AB55" }}
        >
          Done
        </Typography>
      ) : (
        <LinearProgress
          color={wait60Secs ? "success" : "error"}
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          sx={{ width: 0.2 }}
        />
      )}
    </Stack>
  );
}
