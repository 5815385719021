import axios from "axios";

const TELEGRAM_GROUPS_CHAT_IDS = {
  machine_config_logs: "-1001702448210",
  vend_started_debugging: "-4042555342",
};

const WENDOR_BOTS_TOKENS = {
  wendor_bot: "740615893:AAExvhwu3SaC5W2sDmlM9mT7KNFQ0DLQfzs",
};

async function sendAlertOnTelegram(
  botToken: string,
  chatId: string,
  error: any,
  payload: any,
  source:any
) {
  try {
    // The message you want to send
    const message = {
      source: source,
      error: error.message,
      payload,
    };

    // Telegram API endpoint URL
    const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

    // Create a POST request to send the message
    await axios
      .post(apiUrl, {
        chat_id: chatId,
        text: message,
      })
      .then((response: any) => {
        console.log("Message sent:", response.data);
      })
      .catch((error: any) => {
        console.error("Error sending message:", error);
      });
  } catch (error) {
    console.log(error);
  }
}

export { sendAlertOnTelegram, TELEGRAM_GROUPS_CHAT_IDS, WENDOR_BOTS_TOKENS };

