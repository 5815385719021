import mqtt from "precompiled-mqtt";
import MQTTObserver, { ChannelsOrEvents } from "src/observers/mqttObserver";
import getTopicsToSubscribe, { CHANNELS } from "src/utils/mqttChannels";
import { MachineConfigService, TelegramService } from ".";
import axios from "axios";

import { ActionTypes } from "../machineConfig/types";
import { TELEGRAM_GROUPS_CHAT_IDS, WENDOR_BOTS_TOKENS } from "./telegram.service";

export class MQTTService {
  private static instance: MQTTService;
  client: mqtt.MqttClient;
  channelsToSubscribe: CHANNELS;
  mqttObserverInstance: MQTTObserver;

  private constructor(state: any) {
    this.mqttObserverInstance = MQTTObserver.getInstance();
    this.channelsToSubscribe = getTopicsToSubscribe(state);

    const {
      broker_username: username,
      broker_host_url: host,
      broker_port: port,
      broker_password: password,
      broker_use_ssl: useSecureProtocol,
    } = state.broker;

    const clientId = `${state?.machine_id}_${Math.random().toString(16).slice(3)}`;

    var sendMessageToGroup = (function () {
      var executed = false;
      return async function (error: any) {
        if (!executed) {
          executed = true;
          await TelegramService.sendAlertOnTelegram(
            WENDOR_BOTS_TOKENS.wendor_bot,
            TELEGRAM_GROUPS_CHAT_IDS.machine_config_logs,
            error,
            {
              machineId: state.machine_id,
            },
            "MQTT"
          );
        }
      };
    })();

    const url = `${useSecureProtocol ? "wss" : "ws"}://${host}:${port}/mqtt`;
    const options = {
      /* host: "broker.wendor.in",
      port: "8084", */
      /* keepalive: 30, */
      /* protocolId: "MQTTS", */
      protocolVersion: 4,
      /* clean: true, */
      /* reconnectPeriod: 1000,
      connectTimeout: 30 * 1000, */
      /* rejectUnauthorized: false, */
      clientId,
      username,
      password,
    };
    this.client = mqtt.connect(url, options);
    //this.client = mqtt.connect("mqtt://test.mosquitto.org:8081");
    this.client.on("error", (error) => {
      console.log(error);
      sendMessageToGroup(error);
    });

    this.client.on("connect", this.handleOnConnect);

    this.client.on("message", this.handleOnMessage);
  }

  public static init(state: any = {}, forceCreate: boolean = false) {
    if (!MQTTService.instance || forceCreate) {
      MQTTService.instance = new MQTTService(state);
    }
  }

  public static getInstance(): MQTTService {
    return MQTTService.instance;
  }

  public disconnect() {
    this.client.end(true, (err: Error) => {
      if (err) console.log(err);
      console.log("MQTT CONNECTION ENDED.");
    });
  }

  public sendMessage(topic: string, message: any) {
    console.log(
      `CENTRAL SENDER :: TOPIC :: ${topic}, MESSAGE :: `,
      JSON.stringify(message)
    );
    this.client.publish(
      topic,
      JSON.stringify(message),
      { qos: 1, retain: true },
      () => {
        console.log("Message sent to broker.");
      }
    );
  }

  public deleteMessage(topic: string) {
    this.client.publish(topic, "", { qos: 1, retain: true }, () => {
      console.log("Message deleted from broker.");
    });
  }

  public subscribe(...topics: string[]) {
    console.log({ topics });

    this.client.subscribe(topics, function (err, granted) {
      if (err) {
        console.log(`Unable to subscribe.`);
      } else {
        granted.forEach((data) => {
          console.log(`Successfully subscribed to ${data.topic}`);
        });
        // MachineConfigService.checkVMC();
      }
    });
    /* topics.forEach((topic) => {
      this.client.subscribe(topic, function (err) {
        if (err) {
          console.log(`Unable to subscribe ${topic}`);
        } else {
          console.log(`Successfully subscribed to ${topic}`);
        }
      });
    }); */
  }

  public unSubscribe(...topics: string[]) {
    this.client.unsubscribe(topics);
  }

  handleOnConnect() {
    const mqttObserverInstance: MQTTObserver = MQTTObserver.getInstance();
    mqttObserverInstance.notify(ChannelsOrEvents.MQTT_CONNECTON_STATUS, {
      type: ActionTypes.MQTT_CONNECTED,
      payload: {
        isMqttConnected: true,
      },
    });
    const instance: MQTTService = MQTTService.getInstance();
    instance.subscribe(...Object.values(instance.channelsToSubscribe));
  }
  // isVMCConnected: boolean;
  handleOnMessage(topic: any, message: any) {
    const instance: MQTTService = MQTTService.getInstance();
    const mqttObserverInstance: MQTTObserver = MQTTObserver.getInstance();
    const messageRecived: any = JSON.parse(message.toString());

    console.log(`CENTRAL LISTNER :: TOPIC :: ${topic}, MESSAGE :: `, {
      messageRecived,
    });

    switch (topic) {
      case instance.channelsToSubscribe.machineBusy:
        break;
      case instance.channelsToSubscribe.initRespone:
        if (!messageRecived.e && messageRecived.m === "SUCCESS") {
          mqttObserverInstance.notify(ChannelsOrEvents.INIT_RESPONSE, {
            status: true,
          });
        }
        break;
      case instance.channelsToSubscribe.heartBeatResponse:
        switch (messageRecived.m) {
          case "SUCCESS":
            mqttObserverInstance.notify(ChannelsOrEvents.HEARTBEAT_RESPONSE, {
              type: ActionTypes.VMC_CONNECTED,
              payload: {
                isVMCConnected: true,
              },
            });
            break;
          /* case "BREAKDOWN":
            mqttObserverInstance.notify({
              type: ActionTypes.VMC_CONNECTED,
              payload: {
                isVMCConnected: false,
              },
            });
            break; */
          default:
            break;
        }
        break;
      case instance.channelsToSubscribe.orderItemResponse:
        mqttObserverInstance.notify(ChannelsOrEvents.ORDER_ITEM_RESPONSE, {
          status: messageRecived.s,
        });
        break;
      case instance.channelsToSubscribe.lockerItemResponse:
        mqttObserverInstance.notify(ChannelsOrEvents.LOCKER_ITEM_RESPONSE, {
          ...messageRecived,
        });
        break;
      case instance.channelsToSubscribe.orderStatus:
        mqttObserverInstance.notify(ChannelsOrEvents.ORDER_STATUS, {
          status: true,
        });
        break;
      case instance.channelsToSubscribe.cashInitResponse:
        mqttObserverInstance.notify(ChannelsOrEvents.CASH_INIT_RESPONSE, {
          status: messageRecived.isReady,
        });
        break;
      case instance.channelsToSubscribe.cashReceived:
        mqttObserverInstance.notify(ChannelsOrEvents.CASH_RECEIVED, {
          ...messageRecived,
        });
        break;
      case instance.channelsToSubscribe.cashCollectionComplete:
        break;
      case instance.channelsToSubscribe.rfidEnableResponse:
        break;
      case instance.channelsToSubscribe.rfidCardDetails:
        break;
      case instance.channelsToSubscribe.stripePaymentComplete:
        mqttObserverInstance.notify(ChannelsOrEvents.STRIPE_PAYMENT_COMPLETE, {
          status: messageRecived.status === "success",
        });
        break;
      case instance.channelsToSubscribe.orderPicked:
        break;
      case instance.channelsToSubscribe.coinReturnerDispensing:
        break;
      case instance.channelsToSubscribe.coinReturnerDispensed:
        break;
      case instance.channelsToSubscribe.kioskRefresh:
        mqttObserverInstance.notify(ChannelsOrEvents.KIOSK_REFRESH, {
          ...messageRecived,
        });
        break;
      default:
        break;
    }
  }
}
