import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import PressableDial from "./PressableDial";
import { useEffect, useState } from "react";
import PressableButton from "../PressableButton";
import {
  ButtonSecondaryColor,
  COLOR_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "src/theme/new-ui/component.color";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import generateAlpha from "src/theme/new-ui/color.utils";

interface Props {
  digitDimen?: number;
  digitShadowHeight?: number;
  digitFontSize?: number;
  digitSpacing?: number;
  digitMarginTop?: number;
  actionButtonHeight?: number;
  actionButtonRadius?: number;
  actionButtonShadow?: number;
  actionTextSize?: number;
  actionText?: string;
  inputSpacing?: number;
  inputMarginBottom?: number;
  inputBorderWidth?: number;
  inputPadVert?: number;
  inputPadHor?: number;
  inputFieldHeight?: number;
  inputFieldRadius?: number;
  inputFieldShadow?: number;
  inputTextSize?: number;
  isPhoneField?: boolean;
  maxChars?: number;
  dialed: string;
  setDialed: (phoneOnly: string) => void;
  code: string;
  setCode: (codeOnly: string) => void;
  onDialed?: (...data: string[]) => void;
  onAction: (...data: string[]) => void;
}

export default function PressableDialPad({
  digitDimen = 50,
  digitFontSize = 16,
  digitShadowHeight = 6,
  digitSpacing = 4,
  digitMarginTop = 2,
  actionButtonHeight = 32,
  actionButtonRadius = 8,
  actionButtonShadow = 4,
  actionTextSize = 12,
  actionText = "Proceed",
  inputSpacing = 1,
  inputMarginBottom = 2,
  inputBorderWidth = 1,
  inputPadVert = 10,
  inputPadHor = 16,
  inputFieldHeight = 46,
  inputFieldRadius = 8,
  inputFieldShadow = 6,
  inputTextSize = 16,
  isPhoneField = true,
  maxChars = isPhoneField ? 10 : 6,
  dialed,
  setDialed,
  code,
  setCode,
  onDialed,
  onAction,
}: Props) {
  const handleNumberClick = (number: string) => {
    if (dialed.length != maxChars) {
      setDialed(dialed + number);
    }
    // if (onDialed !== null && onDialed !== undefined) onDialed(dialed, code);
  };

  const handleCharDelete = () => {
    if (dialed.length != 0) {
      setDialed(dialed.substring(0, dialed.length - 1));
    }
    // if (onDialed !== null && onDialed !== undefined) onDialed(dialed, code);
  };

  useEffect(() => {
    if (onDialed !== null && onDialed !== undefined) onDialed(dialed, code);
  }, [dialed, code]);

  return (
    <Stack display={"flex"} width={"90%"} direction={"column"}>
      <Box
        sx={{
          height: `${inputFieldHeight + inputFieldShadow}px`,
          borderRadius: `${inputFieldRadius}px`,
          backgroundColor: COLOR_GRAY,
          marginBottom: inputMarginBottom,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            height: `${inputFieldHeight}px`,
            border: `${inputBorderWidth}px solid`,
            borderRadius: `${inputFieldRadius}px`,
            borderColor: COLOR_GRAY,
            backgroundColor: COLOR_WHITE,
            paddingTop: `${inputPadVert}px`,
            paddingBottom: `${inputPadVert}px`,
            paddingLeft: `${inputPadHor}px`,
            paddingRight: `${inputPadHor}px`,
            alignItems: "center",
          }}
          spacing={inputSpacing}
        >
          {/* {isPhoneField && (
            <Typography
              alignItems={"center"}
              fontSize={`${inputTextSize}px`}
              fontWeight={700}
            >
              {code}
            </Typography>
          )} */}
          {/* {isPhoneField && (
            <KeyboardArrowDownIcon sx={{ color: `${COLOR_PRIMARY}` }} />
          )} */}
          <Typography
            alignItems={"center"}
            fontSize={`${inputTextSize}px`}
            fontWeight={700}
          >
            {dialed}
          </Typography>
        </Stack>
      </Box>
      <PressableButton
        fullWidth
        buttonHeight={actionButtonHeight}
        borderRadius={actionButtonRadius}
        shadowHeight={actionButtonShadow}
        fontSize={actionTextSize}
        text={actionText}
        onClick={() => onAction(dialed, code)}
        colorType={ButtonSecondaryColor}
      />
      <Stack
        display={"flex"}
        width={"100%"}
        direction={"column"}
        alignItems={"center"}
        marginTop={digitMarginTop}
      >
        <Stack
          direction={"row"}
          marginTop={digitMarginTop}
          spacing={digitSpacing}
        >
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"1"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"2"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"3"}
            onClick={handleNumberClick}
          />
        </Stack>
        <Stack
          direction={"row"}
          marginTop={digitMarginTop}
          spacing={digitSpacing}
        >
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"4"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"5"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"6"}
            onClick={handleNumberClick}
          />
        </Stack>
        <Stack
          direction={"row"}
          marginTop={digitMarginTop}
          spacing={digitSpacing}
        >
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"7"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"8"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"9"}
            onClick={handleNumberClick}
          />
        </Stack>
        <Stack
          direction={"row"}
          marginTop={digitMarginTop}
          spacing={digitSpacing}
        >
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"."}
            onClick={() => { }}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"0"}
            onClick={handleNumberClick}
          />
          <PressableDial
            dialDimen={digitDimen}
            fontSize={digitFontSize}
            shadowHeight={digitShadowHeight}
            number={"x"}
            onClick={handleCharDelete}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
