import React from "react";
import { useNavigate } from "react-router-dom";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import { PATH_DASHBOARD } from "src/routes/paths";

const ScreenSaverVariant1 = () => {
  const { machineConfiguration } =
    useMachineConfigurationContext();

    const navigate = useNavigate();

  const handleClick = () => {
    navigate(PATH_DASHBOARD.eCommerce.shop);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <img
        src={machineConfiguration.screensaver_image}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        alt="screensaver"
      />
    </div>
  );
};

export default ScreenSaverVariant1;
