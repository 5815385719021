// ----------------------------------------------------------------------

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type CurrencyDetailsType = {
  currencyAlphaCode: string;
  currencyId: number;
  currencyName: string;
  currencyNumericCode: string;
  isSuffix: false;
  symbol: string;
};

export type MachineConfigurationStateType = {
  isLoading: boolean;
  isSuccessful: boolean;
  isFailed: boolean;
  isMachineValidated: boolean;
  isMqttConnected: boolean;
  isVMCConnected: boolean;
  isProductsFetched: boolean;
  products: any[];
  configuration: any;
  enabledPaymentGateways: any;
  currencyDetails?: CurrencyDetailsType | null;
  maxItems: number;
  machineData: any;
  machineConfig: any;
  machineTheme: any;
};

// ----------------------------------------------------------------------

export type MachineConfigContextType = {
  isLoading: boolean;
  isSuccessful: boolean;
  machineConfiguration: any;
  enabledPaymentGateways: any;
  currencyDetails?: CurrencyDetailsType | null;
  products: any[];
  maxItems: number;
  machineData: any;
  refreshMachine: (refreshMachine?: boolean | undefined) => void;
  refetchMachineItemsFetched: VoidFunction;
  machineConfig: any;
  machineTheme: any;
};

export enum ActionTypes {
  INITIAL = "INITIAL",
  REFRESH = "REFRESH",
  SET_CONFIG_DATA = "SET_CONFIG_DATA",
  MACHINE_VALIDATED = "MACHINE_VALIDATED",
  MQTT_CONNECTED = "MQTT_CONNECTED",
  /* MACHINE_VALIDATED = "MACHINE_VALIDATED", */
  VMC_CONNECTED = "VMC_CONNECTED",
  PRODUCTS_FETCHED = "PRODUCTS_FETCHED",
  SET_CONFIGURATION_STATUS = "SET_CONFIGURATION_STATUS",
  VMC_DISCONNECTED = "VMC_DISCONNECTED",
}
