import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PressableButton from "src/components/pressables/PressableButton";
import { getCurrentLanguage } from "src/i18n";
import {
  COLOR_GRAY,
  COLOR_TEXT_ON_WHITE,
} from "src/theme/new-ui/component.color";
import { PAYMENT_METHODS } from "src/constants/productOptions.constants";
import { SocketContext } from "src/context/socketContext/SocketContext";
import SocketObserver, { SocketEvents } from "src/observers/socketObserver";
import { OrderService } from "src/services";
import { useMachineConfigurationContext } from "src/machineConfig/useMachineConfigContext";
import InitiatingCashModule from "src/components/cash-components/InitiatingCashModule";
import EnabledCashModule from "src/components/cash-components/EnabledCashModule";
import DisabledCashModule from "src/components/cash-components/DisabledCashModule";

interface Props {
  cancelOrder: VoidFunction;
  proceedToNextStep: VoidFunction;
}

const OfflineCashVariant1 = ({ cancelOrder, proceedToNextStep }: Props) => {
  const [cashModuleStatus, setCashModuleStatus] = useState("INITIATING");
  const socketObserverInstance = SocketObserver.getInstance();
  const socket = React.useContext(SocketContext);
  const subscriptionIdList = useRef<Array<string>>([]);
  const { currencyDetails, machineData } = useMachineConfigurationContext();

  const displayTexts = getCurrentLanguage(
    useSelector((state: any) => state.seetings.language).language
  );

  const { checkout, total } = useSelector((state: any) => state.product);
  const [title, setTitle] = useState(
    displayTexts ? displayTexts?.payment.cash.pleaseWait : ""
  );
  const [subtitle, setSubtitle] = useState(
    displayTexts ? displayTexts?.payment.cash.dontInsert : ""
  );
  const [totalCash, setTotalCash] = useState(checkout.total);
  const [extraCredit, setExtraCredit] = useState(0);
  const [checkoutResponse, setCheckoutResponse]: any = useState(null);
  const [retryClicked, setRetryClicked]: any = useState(false);

  const data = useRef({
    request_id: checkout.orderId,
    payment_method: PAYMENT_METHODS.CASH,
  });

  const handleEnablingCashModule = async (data: any) => {
    if (data?.topic === "INIT_RESPONSE") {
      if (
        data?.isReady === true &&
        data?.cashStatus === true &&
        data?.coinStatus === true
      ) {
        setCashModuleStatus("ENABLED");
        setTitle("Please Insert Cash");
        setSubtitle(
          displayTexts ? displayTexts?.payment.cash.insertInMachine : ""
        );
      } else if (data?.cashStatus === false && data?.coinStatus === true) {
        setCashModuleStatus("ENABLED");
        setTitle("Please Insert Coin");
        setSubtitle("Cash Acceptor is not working");
      } else if (data?.cashStatus === true && data?.coinStatus === false) {
        setCashModuleStatus("ENABLED");
        setTitle("Please Insert Cash");
        setSubtitle("Coin Acceptor is not working");
      } else {
        setCashModuleStatus("DISABLED");
        setTitle("Not working");
        setSubtitle(data?.message);
        disableCashModule();
      }
    } else if (data?.topic === "BILL_RECEIVED") {
      if (!checkoutResponse?.error) {
        await handleCashInserted(data);
      }
    } else {
      // setCashModuleStatus("PROCESSING");
      setTitle("Processing");
      setSubtitle(displayTexts ? displayTexts?.payment.cash.dontInsert : "");
    }
  };

  const handleCashInserted = async (data: any) => {
    try {
      const response = await OrderService.cashTxnUpdateV2({
        status: "success",
        comment: "no comments",
        note_uuid: data.uuid,
        amount_added: data.billCollected,
        requestId: data.requestId,
        type: data.paymentType,
      });

      if (response.error) cancelOrder();
      if (!response.is_txn_completed) {
        setTotalCash(response.amount_left);
      } else {
        setTotalCash(0);
      }
      if (response.extra_credit) {
        setExtraCredit(response.extra_credit);
      }
      if (response.is_txn_completed) {
        proceedToNextStep();
      }
    } catch (error) {
      console.error(error);
      //   openErrorDialog();
    }
  };

  const disableCashModule = () => {
    socket?.sendMessage(
      SocketEvents.BROADCAST,
      JSON.stringify({
        topic: "DISABLE",
        machineId: Number(machineData.machine_id),
        requestId: checkout.orderId,
      })
    );
    subscriptionIdList.current.forEach((id) =>
      socketObserverInstance.unsubscribe(id)
    );
  };

  const callCheckoutApi = async () => {
    try {
      const response = await OrderService.checkoutRequestV2({
        ...data.current,
        phone: "1234567890",
      });

      return response;
    } catch (error) {
      console.error(error);
      // openErrorDialog();
    }
  };

  useEffect(() => {
    async function init() {
      const res = await callCheckoutApi();
      setCheckoutResponse(res);
    }
    init();
  }, []);

  useEffect(() => {
    if (checkoutResponse !== null) {
      if (checkoutResponse.error) {
        disableCashModule();
        setCashModuleStatus("DISABLED");
        setTitle("Not working");
        setSubtitle(checkoutResponse.message);
      } else {
        subscriptionIdList.current.push(
          socketObserverInstance.subscribe(
            SocketEvents.BROADCAST,
            handleEnablingCashModule
          )
        );

        socket?.sendMessage(
          SocketEvents.BROADCAST,
          JSON.stringify({
            topic: "INIT",
            machineId: Number(machineData.machine_id),
            requestId: checkout.orderId,
            totalCashRequested: checkout.total,
          })
        );
      }
    }

    return () => {
      disableCashModule();
    };
  }, [checkoutResponse]);

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack
        sx={{
          position: "absolute",
          top: 100,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
        marginBottom={3}
        width={"240px"}
      >
        <Typography fontSize={20} fontWeight={700} color={COLOR_TEXT_ON_WHITE}>
          {title}
        </Typography>
        <Typography fontSize={12} fontWeight={400} color={COLOR_TEXT_ON_WHITE}>
          {subtitle}
        </Typography>
        {cashModuleStatus === "INITIATING" && <InitiatingCashModule />}
        {cashModuleStatus === "ENABLED" && (
          <EnabledCashModule
            totalRemainingCash={totalCash}
            totalCashInserted={checkout.total - totalCash + extraCredit}
            totalCash={checkout.total}
            currencyDetails={currencyDetails}
            cancelOrder={cancelOrder}
          />
        )}
        {cashModuleStatus === "DISABLED" && (
          <DisabledCashModule setRetryClicked={setRetryClicked} />
        )}
      </Stack>

      <Stack
        sx={{
          position: "absolute",
          bottom: 20,
        }}
        direction={"column"}
        alignItems={"center"}
        spacing={0}
      >
        <Stack direction={"row"} spacing={1}>
          <PressableButton
            text={displayTexts?.payment.cancelPayment}
            buttonWidth={120} // todo: remove this line while removing en. suffix from language file
            onClick={cancelOrder}
          />
        </Stack>
        <Typography
          fontSize={12}
          fontWeight={600}
          color={COLOR_GRAY}
          marginTop={"12px"}
        >
          {displayTexts?.payment.orderId.replace(
            "{{orderId}}",
            data.current.request_id
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OfflineCashVariant1;
